import { BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS } from "src/sections/@dashboard/intake_sections/constants"
import { getAddressTypes } from "src/sections/@dashboard/intake_sections/schemas/CompanyDetailsSectionSchema"
import { ApplicationTemplate } from "src/types"
import { PDFTemplate } from "src/types/credit/pdf"

export const getPdfTemplate = (template?: ApplicationTemplate) => {
  const businessDetails = {
    title: "Business Details",
    items: [
      {
        label: "Applicant Type",
        type: "input",
        spacing: "48",
        dataField: "applicantType",
      },
      {
        label: "Business Type",
        type: "input",
        spacing: "48",
        dataField: "businessType",
      },
      {
        label: "Legal Business Name",
        type: "input",
        spacing: "48",
        dataField: "legalBusinessName",
      },
      {
        label: "Business DBA",
        type: "input",
        spacing: "48",
        dataField: "businessDba",
      },
      {
        label: "First Name",
        type: "input",
        spacing: "48",
        dataField: "firstName",
      },
      {
        label: "Last Name",
        type: "input",
        spacing: "48",
        dataField: "lastName",
      },
      {
        label: "Email",
        type: "input",
        spacing: "48",
        dataField: "email",
      },
      {
        label: "Phone Number",
        type: "input",
        spacing: "23",
        dataField: "userPhoneNumber",
      },
      {
        label: "Extension",
        type: "input",
        spacing: "23",
        dataField: "userPhoneNumberExt",
      },
      {
        label: "What is your role in the business?",
        type: "text",
        spacing: "48",
      },
      {
        label: "Role",
        type: "input",
        spacing: "48",
        dataField: "role",
      },
      {
        label: "Mailing Address",
        type: "text",
        spacing: "100",
      },
      {
        label: "Address",
        type: "input",
        spacing: "98",
        dataField: "businessAddress",
      },
      {
        label: "Address line 2",
        type: "input",
        spacing: "98",
        dataField: "businessAddressLine2",
      },
      {
        label: "City",
        type: "input",
        spacing: "23",
        dataField: "businessCity",
      },
      {
        label: "Region",
        type: "input",
        spacing: "23",
        dataField: "businessRegion",
      },
      {
        label: "Postal/Zip Code",
        type: "input",
        spacing: "23",
        dataField: "businessPostCode",
      },
      {
        label: "Country",
        type: "input",
        spacing: "23",
        dataField: "businessCountry",
      },
      {
        label: "Shipping Address",
        type: "text",
        spacing: "100",
      },
      {
        label: "Address",
        type: "input",
        spacing: "98",
        dataField: "shippingAddress",
      },
      {
        label: "Address line 2",
        type: "input",
        spacing: "98",
        dataField: "shippingAddressLine2",
      },
      {
        label: "City",
        type: "input",
        spacing: "23",
        dataField: "shippingCity",
      },
      {
        label: "Region",
        type: "input",
        spacing: "23",
        dataField: "shippingRegion",
      },
      {
        label: "Postal/Zip Code",
        type: "input",
        spacing: "23",
        dataField: "shippingPostCode",
      },
      {
        label: "Country",
        type: "input",
        spacing: "23",
        dataField: "shippingCountry",
      },
    ],
  }

  if (
    getAddressTypes(template).includes(
      BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS,
    )
  ) {
    businessDetails.items = businessDetails.items.concat([
      {
        label: "Billing Address",
        type: "text",
        spacing: "100",
      },
      {
        label: "Address",
        type: "input",
        spacing: "98",
        dataField: "billingAddress",
      },
      {
        label: "Address line 2",
        type: "input",
        spacing: "98",
        dataField: "",
      },
      {
        label: "City",
        type: "input",
        spacing: "23",
        dataField: "billingCity",
      },
      {
        label: "Region",
        type: "input",
        spacing: "23",
        dataField: "billingRegion",
      },
      {
        label: "Postal/Zip Code",
        type: "input",
        spacing: "23",
        dataField: "billingPostCode",
      },
      {
        label: "Country",
        type: "input",
        spacing: "23",
        dataField: "billingCountry",
      },
    ])
  }

  businessDetails.items = businessDetails.items.concat([
    {
      label: "What is the annual revenue of your business?",
      type: "text",
      spacing: "48",
    },
    {
      label: "Revenue",
      type: "input",
      spacing: "48",
      dataField: "revenue",
    },
  ])
  return {
    "Business Details": businessDetails,
    "Owners and Contacts": {
      title: "Owners and Contacts",
      items: [
        {
          label: "Owner 1",
          type: "text",
          spacing: "100",
        },
        {
          label: "Full Name",
          type: "input",
          spacing: "48",
          dataField: "ownerName1",
        },
        {
          label: "Email",
          type: "input",
          spacing: "48",
          dataField: "ownerEmail1",
        },
        {
          label: "Owner Title",
          type: "input",
          spacing: "48",
          dataField: "ownerTitle1",
        },
        {
          label: "Owner Percentage",
          type: "input",
          spacing: "48",
          dataField: "ownerPercentage1",
        },
        {
          label: "Driver License Number",
          type: "input",
          spacing: "48",
          dataField: "ownerDriverLicense1",
        },
        {
          label: "SSN/SIN",
          type: "input",
          spacing: "48",
          dataField: "ownerSsn1",
        },
        {
          label: "Date of Birth",
          type: "input",
          spacing: "48",
          dataField: "ownerDob1",
        },
        {
          label: "Phone Number",
          type: "input",
          spacing: "48",
          dataField: "ownerPhoneNumber1",
        },
        {
          label: "Address",
          type: "input",
          spacing: "98",
          dataField: "ownerAddress1",
        },
        {
          label: "Address Line 2",
          type: "input",
          spacing: "98",
          dataField: "",
        },
        {
          label: "Owner 2",
          type: "text",
          spacing: "100",
        },
        {
          label: "Full Name",
          type: "input",
          spacing: "48",
          dataField: "ownerName2",
        },
        {
          label: "Email",
          type: "input",
          spacing: "48",
          dataField: "ownerEmail2",
        },
        {
          label: "Owner Title",
          type: "input",
          spacing: "48",
          dataField: "ownerTitle2",
        },
        {
          label: "Owner Percentage",
          type: "input",
          spacing: "48",
          dataField: "ownerPercentage2",
        },
        {
          label: "Driver License Number",
          type: "input",
          spacing: "48",
          dataField: "ownerDriverLicense2",
        },
        {
          label: "SSN/SIN",
          type: "input",
          spacing: "48",
          dataField: "ownerSsn2",
        },
        {
          label: "Date of Birth",
          type: "input",
          spacing: "48",
          dataField: "ownerDob2",
        },
        {
          label: "Phone Number",
          type: "input",
          spacing: "48",
          dataField: "ownerPhoneNumber2",
        },
        {
          label: "Address",
          type: "input",
          spacing: "98",
          dataField: "ownerAddress2",
        },
        {
          label: "Address Line 2",
          type: "input",
          spacing: "98",
          dataField: "",
        },
        {
          label: "Contacts",
          type: "text",
          spacing: "100",
        },
        {
          label: "Credit/AP Email",
          type: "input",
          spacing: "98",
          dataField: "apEmail",
        },
        {
          label: "Credit/AP Phone Number",
          type: "input",
          spacing: "48",
          dataField: "apPhoneNumber",
        },
        {
          label: "Extension",
          type: "input",
          spacing: "48",
          dataField: "apPhoneNumberExt",
        },
      ],
    },
    "Trade References": {
      title: "Trade References",
      items: [
        {
          label: "Reference 1",
          spacing: "100",
          type: "text",
        },
        {
          label: "Business Name",
          spacing: "48",
          type: "input",
          dataField: "referenceBusinessName1",
        },
        {
          label: "Account # (Optional)",
          spacing: "48",
          type: "input",
          dataField: "referenceAccountNumber1",
        },
        {
          label: "Credit/Ar Email",
          spacing: "48",
          type: "input",
          dataField: "referenceEmail1",
        },
        {
          label: "Credit/Ar Phone Number",
          spacing: "48",
          type: "input",
          dataField: "referencePhoneNumber1",
        },
        {
          label: "Reference 2",
          spacing: "100",
          type: "text",
        },
        {
          label: "Business Name",
          spacing: "48",
          type: "input",
          dataField: "referenceBusinessName2",
        },
        {
          label: "Account # (Optional)",
          spacing: "48",
          type: "input",
          dataField: "referenceAccountNumber2",
        },
        {
          label: "Credit/Ar Email",
          spacing: "48",
          type: "input",
          dataField: "referenceEmail2",
        },
        {
          label: "Credit/Ar Phone Number",
          spacing: "48",
          type: "input",
          dataField: "referencePhoneNumber2",
        },
        {
          label: "Reference 3",
          spacing: "100",
          type: "text",
        },
        {
          label: "Business Name",
          spacing: "48",
          type: "input",
          dataField: "referenceBusinessName3",
        },
        {
          label: "Account # (Optional)",
          spacing: "48",
          type: "input",
          dataField: "referenceAccountNumber3",
        },
        {
          label: "Credit/Ar Email",
          spacing: "48",
          type: "input",
          dataField: "referenceEmail3",
        },
        {
          label: "Credit/Ar Phone Number",
          spacing: "48",
          type: "input",
          dataField: "referencePhoneNumber3",
        },
      ],
    },
    "Bank Reference": {
      title: "Bank Reference",
      items: [
        {
          label: "We will not use this information for any payment processing",
          type: "text",
          spacing: "100",
        },
        {
          label: "Bank Name",
          type: "input",
          spacing: "98",
          dataField: "bankName",
        },
        {
          label: "Account Number",
          type: "input",
          spacing: "31",
          dataField: "bankAccountNumber",
        },
        {
          label: "Transit Number",
          type: "input",
          spacing: "31",
          dataField: "bankTransitNumber",
        },
        {
          label: "Institution Number",
          type: "input",
          spacing: "31",
          dataField: "bankInstitutionNumber",
        },
        {
          label: "Routing Number",
          type: "input",
          spacing: "98",
          dataField: "bankRoutingNumber",
        },
        {
          label: "Account Manager Name",
          type: "input",
          spacing: "48",
          dataField: "bankContactName",
        },
        {
          label: "Account Manager Email",
          type: "input",
          spacing: "48",
          dataField: "bankContactEmail",
        },
        {
          label: "Account Manager Phone Number",
          type: "input",
          spacing: "98",
          dataField: "bankContactPhoneNumber",
        },
      ],
    },
    "Additional Questions": {
      title: "Additional Questions",
      items: [
        {
          label: "The following questions are optional",
          type: "text",
          spacing: "100",
        },
      ],
    },
    "Personal Guaranty": {
      title: "Personal Guaranty",
      items: [
        {
          label:
            "A personal guarantor (PG) helps us assess your application. Please fill out the form below. The PG document will be sent to the email(s) you specify in this form to be digitally signed. Therefore, as a minimum, we recommend that the email address of the guarantor(s) is included.",
          type: "text",
          spacing: "100",
        },
        {
          label: "Personal Guarantor 1",
          type: "text",
          spacing: "100",
        },
        {
          label: "Full Name",
          type: "input",
          spacing: "48",
          dataField: "guarantorName",
        },
        {
          label: "Email",
          type: "input",
          spacing: "48",
          dataField: "guarantorEmail",
        },
        {
          label: "SSN (US) or SIN (Canada)",
          type: "input",
          spacing: "48",
          dataField: "guarantorSsn",
        },
        {
          label: "Phone",
          type: "input",
          spacing: "48",
          dataField: "guarantorPhoneNumber",
        },
        {
          label: "Personal Guarantor 2",
          type: "text",
          spacing: "100",
        },
        {
          label: "Full Name",
          type: "input",
          spacing: "48",
          dataField: "guarantorName2",
        },
        {
          label: "Email",
          type: "input",
          spacing: "48",
          dataField: "guarantorEmail2",
        },
        {
          label: "SSN (US) or SIN (Canada)",
          type: "input",
          spacing: "48",
          dataField: "guarantorSsn2",
        },
        {
          label: "Phone",
          type: "input",
          spacing: "48",
          dataField: "guarantorPhoneNumber2",
        },
        {
          label: "Personal Guarantor 3",
          type: "text",
          spacing: "100",
        },
        {
          label: "Full Name",
          type: "input",
          spacing: "48",
          dataField: "guarantorName3",
        },
        {
          label: "Email",
          type: "input",
          spacing: "48",
          dataField: "guarantorEmail3",
        },
        {
          label: "SSN (US) or SIN (Canada)",
          type: "input",
          spacing: "48",
          dataField: "guarantorSsn3",
        },
        {
          label: "Phone",
          type: "input",
          spacing: "48",
          dataField: "guarantorPhoneNumber3",
        },
      ],
    },
  } as PDFTemplate
}
