import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material"
import { Outlet } from "react-router-dom"
import { useLocation, useParams } from "react-router-dom"
import { useAnonymousBusiness } from "../../queries/credit/useAnonymousBusiness"
import { useEffect } from "react"
import { loadLogoIntoImg } from "../../utils/imageTools"
import { DynamicThemeProvider } from "../../theme"
import { useCreditApplication } from "src/queries/credit/useCreditApplication"
import { LogoutOutlined } from "@mui/icons-material"
import { useAuth0 } from "@auth0/auth0-react"
import { useCashApplication } from "src/queries/cod/useCashApplication"
import { getDisplayContent } from "src/utils/form-content"
// ----------------------------------------------------------------------

export default function () {
  const { search, pathname } = useLocation()
  const queryParams = new URLSearchParams(search)
  const businessId = queryParams.get("business_id") || ""

  const { data } = useAnonymousBusiness(businessId)
  const { id } = useParams()
  const { data: application } = useCreditApplication(
    pathname.includes("cod") ? undefined : id,
  )

  const { data: cashApplication } = useCashApplication(
    pathname.includes("cod") ? id : undefined,
  )
  const displayContent = getDisplayContent(
    queryParams.get("request_type") || "",
  )

  const { logout } = useAuth0()

  const logo =
    data?.logo || application?.seller?.logo || cashApplication?.seller?.logo

  useEffect(() => {
    loadLogoIntoImg(document.getElementById("company-logo"), logo)
  }, [logo])

  return (
    <DynamicThemeProvider>
      {!data && (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <CircularProgress color="info" />
        </Box>
      )}
      {data && (
        <>
          <Grid container component="main" sx={{ height: "100vh" }}>
            <Grid
              item
              md={4}
              lg={4}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                width: "100%",
              }}
              sx={{ height: "auto" }}
            >
              <Box
                display={{ xs: "none", lg: "block", xl: "block" }}
                sx={{
                  padding: "10%",
                }}
              >
                <img id="company-logo" style={{ padding: "10% 30% 20% 30%" }} />
                <Typography variant="h4">{displayContent}</Typography>
              </Box>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "16px",
                  bottom: "5%",
                  position: "absolute",
                }}
              >
                <Box
                  display={{ xs: "none", lg: "flex", xl: "flex" }}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <img src="/assets/soc2.png" style={{ width: "72px" }} />
                  <img src="/assets/ssl.png" style={{ height: "72px" }} />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    powered by
                  </Typography>
                  <img
                    src="/assets/logo-simple.png"
                    style={{ width: "32px" }}
                  />
                  <Typography sx={{ fontWeight: "bold" }}>NetNow</Typography>
                  <Button
                    variant="outlined"
                    style={{ marginLeft: "16px" }}
                    startIcon={<LogoutOutlined />}
                    onClick={() => {
                      logout()
                    }}
                  >
                    Log out
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              component={Paper}
              style={{
                backgroundColor: "#F9FAFB",
              }}
              elevation={4}
              square
            >
              <Outlet />
            </Grid>
          </Grid>
        </>
      )}
    </DynamicThemeProvider>
  )
}
