import {
  Button,
  FormControl,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { Formik } from "formik"
import { enqueueSnackbar } from "notistack"
import { usePatchCashApplicationTemplate } from "src/queries/internal/usePatchCashApplicationTemplate"
import { PAGE_LABEL_USER_DETAILS } from "src/sections/@dashboard/intake_sections/constants"
import { ApplicationTemplate } from "src/types"

export default function CashSectionZohoTemplateId({
  applicationTemplate,
  refetch,
}: {
  applicationTemplate: ApplicationTemplate
  refetch: () => void
}) {
  const { execute, isLoading } = usePatchCashApplicationTemplate(() => {
    enqueueSnackbar("Application Template Updated", { variant: "success" })
  })

  return (
    <>
      <Paper elevation={3} sx={{ marginY: 2, p: 1 }}>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          {PAGE_LABEL_USER_DETAILS} Section Configuration
        </Typography>
        <Formik
          initialValues={
            {
              id: applicationTemplate.id,
              zohoTemplateId: applicationTemplate.zohoTemplateId,
            } as ApplicationTemplate
          }
          onSubmit={(values) => {
            execute(values, () => {
              refetch()
            })
          }}
        >
          {(props) => (
            <FormControl component="fieldset" variant="standard">
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Zoho Template Id"
                  id="zohoTemplateId"
                  name="zohoTemplateId"
                  margin="normal"
                  type="text"
                  value={props.values.zohoTemplateId}
                  onChange={props.handleChange}
                  error={Boolean(props.errors?.zohoTemplateId)}
                />
              </Stack>
              <Stack sx={{ marginY: 2 }}>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  onClick={() => {
                    props.handleSubmit()
                  }}
                >
                  Save
                </Button>
              </Stack>
            </FormControl>
          )}
        </Formik>
      </Paper>
    </>
  )
}
