import {
    Button,
  Divider,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { ApplicationTemplate, Page } from "src/types"

export default function EditConfigComponent({
  page,
  template,
  refetch,
}: {
  page: Page
  template: ApplicationTemplate
  refetch: () => void
}) {
  const row = (label: string, value: string, index: number) => {
    return (
      <TableRow key={index + label}>
        <TableCell>{label}</TableCell>
        <TableCell>{value}</TableCell>
        <TableCell>
          <Button
            onClick={() => {
                console.log("Delete")
            }}></Button>
        </TableCell>
      </TableRow>
    )
  }
  return (
    <>
      <Divider sx={{ marginTop: 1 }} />
      <TableContainer component={Paper} sx={{ m: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell>Key</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
      </TableContainer>
    </>
  )
}
