import { useEffect } from "react"
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material"
import { Outlet } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { useAnonymousBusiness } from "../../queries/credit/useAnonymousBusiness"
import { loadLogoIntoImg } from "../../utils/imageTools"
import { DynamicThemeProvider } from "../../theme"
import { getDisplayContent } from "src/utils/form-content"
// ----------------------------------------------------------------------

export default function NoAuthSimpleLayout() {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const businessId = queryParams.get("business_id") || ""

  const { data } = useAnonymousBusiness(businessId)
  const displayContent = getDisplayContent(
    queryParams.get("request_type") || "",
  )

  useEffect(() => {
    loadLogoIntoImg(document.getElementById("company-logo"), data?.logo)
  }, [data])

  if (!data)
    return (
      <Box
        style={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <CircularProgress color="info" />
      </Box>
    )

  return (
    <DynamicThemeProvider>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          md={4}
          lg={4}
          style={{
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
          }}
          sx={{ height: "auto" }}
        >
          <Box
            display={{ xs: "none", lg: "block", xl: "block" }}
            style={{
              backgroundColor: "#fff",
            }}
            sx={{
              padding: "10%",
            }}
          >
            <img id="company-logo" style={{ padding: "10% 30% 20% 30%" }} />
            <Typography variant="h4">{displayContent}</Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "16px",
              bottom: "5%",
              position: "absolute",
            }}
          >
            <Box
              display={{ xs: "none", lg: "flex", xl: "flex" }}
              style={{
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img src="/assets/soc2.png" style={{ width: "72px" }} />
              <img src="/assets/ssl.png" style={{ height: "72px" }} />
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>powered by</Typography>
              <img src="/assets/logo-simple.png" style={{ width: "32px" }} />
              <Typography sx={{ fontWeight: "bold" }}>NetNow</Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          component={Paper}
          elevation={6}
          square
        >
          <Outlet />
        </Grid>
      </Grid>
    </DynamicThemeProvider>
  )
}
