import { ApplicationTemplate } from "src/types"
import CreditSectionUserDetails from "./ApplicationTemplate/CreditSectionUserDetails"
import CreditSectionBusinessDetails from "./ApplicationTemplate/CreditSectionBusinessDetails"
import CreditSectionOwnerAndContactsDetails from "./ApplicationTemplate/CreditSectionOwnerAndContactsDetails"
import CreditSectionTradeReferencesDetails from "./ApplicationTemplate/CreditSectionTradeReferencesDetails"
import CreditSectionBankReferenceDetails from "./ApplicationTemplate/CreditSectionBankReferenceDetails"
import CreditSectionPersonalGuarantyDetails from "./ApplicationTemplate/CreditSectionPersonalGuarantyDetails"

export default function EditCreditApplicationTemplate({
  applicationTemplate,
  refetch,
}: {
  applicationTemplate: ApplicationTemplate
  refetch: () => void
}) {
  return (
    <>
      <CreditSectionUserDetails
        applicationTemplate={applicationTemplate}
        refetch={refetch}
      />
      <CreditSectionBusinessDetails
        applicationTemplate={applicationTemplate}
        refetch={refetch}
      />
      <CreditSectionOwnerAndContactsDetails
        applicationTemplate={applicationTemplate}
        refetch={refetch}
      />
      <CreditSectionTradeReferencesDetails
        applicationTemplate={applicationTemplate}
        refetch={refetch}
      />
      <CreditSectionBankReferenceDetails
        applicationTemplate={applicationTemplate}
        refetch={refetch}
      />
      <CreditSectionPersonalGuarantyDetails
        applicationTemplate={applicationTemplate}
        refetch={refetch}
      />
    </>
  )
}
