// @mui
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"

import { Box, Card, Divider, Tab, Tabs } from "@mui/material"

// ----------------------------------------------------------------------

import { useTradeReferences } from "src/queries/credit/useTradeReferences"
import Label from "src/components/label"
import { PAGE_LABEL_BUSINESS_DETAILS } from "../intake_sections/constants"
import { CreditApplication } from "src/types"

import { useEmailStatus } from "src/queries/vendors/useEmailStatus"

export default function ({
  application,
  tab,
  setTab,
}: {
  application: CreditApplication
  tab: number
  setTab: (value: number) => void
}) {
  const applicationId = application.id || ""
  const { data: businessTemplate } = useApplicationTemplate()

  const templateData = application?.template || businessTemplate?.formTemplate

  let addressTypes = templateData?.pages
    .find((page) => page.label === PAGE_LABEL_BUSINESS_DETAILS)
    ?.config?.filter((config) => config.label.includes("Address"))
    ?.filter((c) => c.value === true)
    ?.map((c) => c.label)

  if (!addressTypes || addressTypes.length === 0) {
    addressTypes = ["Business Address", "Shipping Address"]
  }

  const { data: tradeRefs } = useTradeReferences(applicationId)

  const {} = useEmailStatus(tradeRefs?.map((r) => r.email) || [], applicationId)

  if (!application) {
    return <div>Loading...</div>
  }

  return (
    <Card sx={{ marginBottom: "15px" }}>
      <>
        <Tabs
          value={tab}
          onChange={(event: React.SyntheticEvent, value: any) => {
            setTab(value)
          }}
          variant="fullWidth"
        >
          <Tab
            label="Applicant / Business"
            tabIndex={0}
            sx={{
              border: "solid",
              borderWidth: "1px",
              borderColor: "#eeeeee",
              background: "rgb(252, 252, 252)",
            }}
          />
          <Tab
            label="Owners & Contacts"
            tabIndex={1}
            sx={{
              border: "solid",
              borderWidth: "1px",
              borderColor: "#eeeeee",
              background: "rgb(252, 252, 252)",
            }}
          />
          <Tab
            label={
              <>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  Trade References
                  {tradeRefs && (
                    <Label
                      color={
                        tradeRefs?.length === 0
                          ? "default"
                          : tradeRefs?.filter((t) => t.isFilled).length
                            ? "success"
                            : "error"
                      }
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      {`${tradeRefs?.filter((t) => t.isFilled).length} / 
         ${tradeRefs?.length}`}
                    </Label>
                  )}
                </Box>
              </>
            }
            tabIndex={2}
            sx={{
              border: "solid",
              borderWidth: "1px",
              borderColor: "#eeeeee",
              background: "rgb(252, 252, 252)",
            }}
          />
          <Tab
            label={
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                Guarantor
              </Box>
            }
            tabIndex={3}
            sx={{
              border: "solid",
              borderWidth: "1px",
              borderColor: "#eeeeee",
              background: "rgb(252, 252, 252)",
            }}
          />
          <Tab
            label={
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                Files
                <Label
                  color={"warning"}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  {application.files?.length || 0}
                </Label>
              </Box>
            }
            tabIndex={4}
            sx={{
              border: "solid",
              borderWidth: "1px",
              borderColor: "#eeeeee",
              background: "rgb(252, 252, 252)",
            }}
          />
          <Tab
            label={
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                Registry and Bureaus
              </Box>
            }
            tabIndex={5}
            sx={{
              border: "solid",
              borderWidth: "1px",
              borderColor: "#eeeeee",
              background: "rgb(252, 252, 252)",
            }}
          />
          {!application.seller?.name.includes("Quecan") && (
            <Tab
              label={
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  Bank Reference
                </Box>
              }
              tabIndex={6}
              sx={{
                border: "solid",
                borderWidth: "1px",
                borderColor: "#eeeeee",
                background: "rgb(252, 252, 252)",
              }}
            />
          )}
        </Tabs>
        <Divider />
      </>
    </Card>
  )
}
