import {
  Box,
  Button,
  Autocomplete,
  Chip,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import Label from "src/components/label"
import { SchemaDescription, SchemaObjectDescription } from "yup/lib/schema"
import * as yup from "yup"
import {
  ApplicationTemplate,
  CreditApplication,
  TemplateCustomField,
  Application,
  CustomFieldResponse,
} from "src/types"
import {
  APPLICATION_STAGE,
  FIELD_TYPES,
  BUSINESS_TYPE_OPTIONS,
  APPLICANT_TYPES,
} from "src/statics"
import {
  ADDITIONAL_QUESTIONS_DATA_KEY,
  BANK_REFERENCE_DATA_KEY,
  BUSINESS_DETAILS_DATA_KEY,
  FORM_INPUT_NAMES,
  OWNERS_DATA_KEY,
  PAGE_LABEL_ADDITIONAL_QUESTIONS,
  PAGE_LABEL_BANK_REFERENCE,
  PAGE_LABEL_BUSINESS_DETAILS,
  PAGE_LABEL_OWNERS,
  PAGE_LABEL_PERSONAL_GUARANTY,
  PAGE_LABEL_TRADE_REFERENCES,
  PAGE_LABEL_USER_DETAILS,
  PERSONAL_GUARANTY_DATA_KEY,
  TRADE_REFERENCE_DATA_KEY,
  USER_DETAILS_DATA_KEY,
} from "./intake_sections/constants"
import { formatCurrency } from "src/utils/formatNumber"
import { USER_CONFIG_BUSINESS_TYPE_LIST } from "./intake_sections/constants"

export const getStatus = (stage: number) => {
  if (stage === APPLICATION_STAGE.ONE) {
    return <Chip color={"primary"} label="Incomplete" />
  }

  if (stage === APPLICATION_STAGE.AWAITING_SIGNATURE) {
    return <Chip color={"warning"} label="Awaiting Signature" />
  }

  if (stage === APPLICATION_STAGE.COMPLETED_BY_CLIENT) {
    return <Chip color={"success"} label="Completed By Client" />
  }

  if (stage === APPLICATION_STAGE.APPROVED) {
    return <Chip color={"success"} label="Approved" />
  }

  if (stage === APPLICATION_STAGE.REJECTED) {
    return <Chip color={"error"} label="Rejected" />
  }

  if (stage === APPLICATION_STAGE.ARCHIVE) {
    return <Chip color={"info"} label="Archived" />
  }
}

export const getStatusLabel = (stage: number) => {
  if (stage === APPLICATION_STAGE.ONE) {
    return <Label color={"primary"}>Incomplete</Label>
  }

  if (stage === APPLICATION_STAGE.AWAITING_SIGNATURE) {
    return <Label color={"warning"}>Awaiting Signature</Label>
  }

  if (stage === APPLICATION_STAGE.COMPLETED_BY_CLIENT) {
    return <Label color={"success"}>Completed By Client</Label>
  }

  if (stage === APPLICATION_STAGE.APPROVED) {
    return <Label color={"success"}>Approved</Label>
  }

  if (stage === APPLICATION_STAGE.REJECTED) {
    return <Label color={"error"}>Rejected</Label>
  }

  if (stage === APPLICATION_STAGE.MANUAL_INCOMPLETE) {
    return <Label color={"primary"}>Manual / Incomplete</Label>
  }

  if (stage === APPLICATION_STAGE.MANUAL_COMPLETE) {
    return <Label color={"success"}>Manual / Complete</Label>
  }

  if (stage === APPLICATION_STAGE.ARCHIVE) {
    return <Label color={"info"}>Archived</Label>
  }
}

export const getStatusString = (stage: number) => {
  if (stage === APPLICATION_STAGE.ONE) {
    return "Incomplete"
  }

  if (stage === APPLICATION_STAGE.MANUAL_INCOMPLETE) {
    return "Manual / Incomplete"
  }

  if (stage === APPLICATION_STAGE.MANUAL_COMPLETE) {
    return "Manual / Complete"
  }

  if (stage === APPLICATION_STAGE.AWAITING_SIGNATURE) {
    return "Awaiting Signature"
  }

  if (stage === APPLICATION_STAGE.COMPLETED_BY_CLIENT) {
    return "Completed By Client"
  }

  if (stage === APPLICATION_STAGE.APPROVED) {
    return "Approved"
  }

  if (stage === APPLICATION_STAGE.REJECTED) {
    return "Rejected"
  }
  if (stage === APPLICATION_STAGE.ARCHIVE) {
    return "Archived"
  }
}

export const isDataFieldRequired = (
  schema: yup.AnyObjectSchema,
  fieldName: string,
) => {
  let required = false
  try {
    required = !!(
      (schema.describe().fields["data"] as SchemaObjectDescription).fields[
        fieldName
      ] as SchemaDescription
    ).tests.find((t) => t.name === "required")
  } catch (e) {
    return required
  }
  return required
}

export const getBusinessTypes = (template: ApplicationTemplate) => {
  const configTypes = template.formTemplate.pages
    .find((step) => step.label === PAGE_LABEL_USER_DETAILS)
    ?.config?.find((config) => config.label === USER_CONFIG_BUSINESS_TYPE_LIST)
    ?.value as string[]
  return configTypes && configTypes.length > 0
    ? configTypes
    : BUSINESS_TYPE_OPTIONS
}

export const getAdditionalQuestionSchemaForPage = (
  template: ApplicationTemplate,
  page: string,
) => {
  let customSchema = yup.object({})
  template.customFields
    ?.filter((field) => field.associatedPage === page)
    .filter((field) => field.required && !!field.id)
    .filter((field) => !hasConditions(field))
    .forEach((field) => {
      if (field.fieldType === "file") {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup.mixed().required("Please upload a file"),
          }),
        )
      } else {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup
              .object({
                response: yup.string().required("Field is required"),
              })
              .required("Field is required"),
          }),
        )
      }
    })
  return customSchema
}

/**
 * Renders custom questions for a given page label.
 * This is used in Application Details page.
 */
export const renderCustomQuestionsFor = (
  businessTemplate: ApplicationTemplate | undefined,
  application: CreditApplication,
  associatedPage: string,
  onVerifyContractorLicense: () => void,
  onOpenDocument: (file: string) => void,
) => {
  if (!application?.data?.customFields) {
    return <></>
  }
  const questions = businessTemplate?.customFields
    ?.filter((field) => field.id !== undefined && field.id !== null)
    ?.filter((field) => field.associatedPage === associatedPage)
    ?.filter((field) =>
      Boolean(
        Object.keys(application?.data?.customFields).includes(
          field.id as string,
        ),
      ),
    )
    ?.map((field) => {
      return {
        ...field,
        ...application.data.customFields[field.id as string],
      }
    })

  const numberOfQuestions = questions?.length || 0

  if (numberOfQuestions === 0) {
    return <></>
  }

  return (
    <>
      {questions?.map((field: any, index: number) => {
        return (
          <>
            <Stack
              direction="row"
              key={field.fieldId}
              justifyContent="space-between"
              width="auto"
              minHeight="4rem"
              // sx={index % 2 === 1 ? { backgroundColor: "#eeeeee" } : {}}
            >
              <Box
                style={{ width: "60%", minWidth: "60%" }}
                sx={{
                  color: "text.secondary",
                  flexWrap: "wrap",
                  display: "flex",
                  alignSelf: "center",
                }}
              >
                <Typography variant="body2" style={{ wordBreak: "break-word" }}>
                  {field.text}
                </Typography>
              </Box>

              <Box
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignSelf: "center",
                }}
              >
                {field.fieldType !== "file" ? (
                  <>
                    {field.text.includes("Contractor License") && (
                      <Button
                        onClick={() => {
                          onVerifyContractorLicense()
                        }}
                        variant="contained"
                        style={{ marginRight: "8px" }}
                      >
                        Verify
                      </Button>
                    )}
                    <Typography
                      variant="body2"
                      style={{ wordBreak: "break-word" }}
                    >
                      {field.fieldType === FIELD_TYPES.AMOUNT
                        ? formatCurrency(field.response.replace(/,/g, ""))
                        : field.response}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        onOpenDocument(field.file)
                      }}
                      disabled={!field.file}
                      variant="contained"
                    >
                      {field.file ? "View" : "Skipped"}
                    </Button>
                  </>
                )}
              </Box>
            </Stack>
          </>
        )
      })}
    </>
  )
}

export const hasConditions = (field: TemplateCustomField) => {
  if (
    field &&
    field.conditions &&
    field.conditions.length > 0 &&
    Object.keys(field.conditions[0]).length > 0
  ) {
    return true
  }
  return false
}

export const getTemplateCustomField = (
  id: string,
  data: ApplicationTemplate | undefined,
) => {
  if (data?.customFields) {
    for (const customField of data.customFields) {
      if (customField.id === id) {
        return customField
      }
    }
    return undefined
  }
}

export const getDropdownField = (
  field: TemplateCustomField | undefined,
  required = false,
  onChange: (value: any) => void,
  value = "",
) => {
  if (field) {
    if (field.fieldType === FIELD_TYPES.DROPDOWN) {
      return (
        <FormControl fullWidth required={!!required}>
          <InputLabel id={`${field.id}-label`}>Response</InputLabel>
          <Select
            labelId={`${field.id}-label`}
            id={`${field.id}-select`}
            value={value}
            defaultValue={value}
            onChange={(event) => {
              onChange(event.target.value || "")
            }}
            label="Select One"
          >
            <MenuItem value={""} disabled>
              Select One
            </MenuItem>
            {field.options &&
              field.options?.split(",").map((option, optionIndex) => (
                <MenuItem value={option} key={optionIndex}>
                  {option}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )
    }
  }
}

export const getMultiSelectField = (
  field: TemplateCustomField | undefined,
  onChange: (value: any) => void,
  values: string[],
) => {
  if (field) {
    if (values === undefined) {
      values = []
    }
    if (field.fieldType === FIELD_TYPES.MULTISELECT) {
      return (
        <Autocomplete
          fullWidth
          id={`${field.id}-search`}
          multiple
          sx={{
            width: "100%",
          }}
          options={field.options?.split(",") || []}
          defaultValue={[]}
          value={values}
          onChange={(_, value) => {
            if (field.id) {
              onChange(value)
            }
          }}
          renderInput={(params) => <TextField {...params} label="Response" />}
        />
      )
    }
  }
}

export const getDataKeyName = (dataKey: string, associatedPage: string) => {
  if (dataKey === "0") {
    // 0: FORM_INPUT_NAMES.APPLICANT_TYPE for all sections
    return FORM_INPUT_NAMES.APPLICANT_TYPE
  }
  if (
    associatedPage === PAGE_LABEL_USER_DETAILS &&
    Object.keys(USER_DETAILS_DATA_KEY).includes(dataKey)
  ) {
    return USER_DETAILS_DATA_KEY[Number(dataKey)]
  }

  if (
    associatedPage === PAGE_LABEL_BUSINESS_DETAILS &&
    Object.keys(BUSINESS_DETAILS_DATA_KEY).includes(dataKey)
  ) {
    return BUSINESS_DETAILS_DATA_KEY[Number(dataKey)]
  }

  if (
    associatedPage === PAGE_LABEL_OWNERS &&
    Object.keys(OWNERS_DATA_KEY).includes(dataKey)
  ) {
    return OWNERS_DATA_KEY[Number(dataKey)]
  }

  if (
    associatedPage === PAGE_LABEL_BANK_REFERENCE &&
    Object.keys(BANK_REFERENCE_DATA_KEY).includes(dataKey)
  ) {
    return BANK_REFERENCE_DATA_KEY[Number(dataKey)]
  }

  if (
    associatedPage === PAGE_LABEL_TRADE_REFERENCES &&
    Object.keys(TRADE_REFERENCE_DATA_KEY).includes(dataKey)
  ) {
    return TRADE_REFERENCE_DATA_KEY[Number(dataKey)]
  }

  if (
    associatedPage === PAGE_LABEL_PERSONAL_GUARANTY &&
    Object.keys(PERSONAL_GUARANTY_DATA_KEY).includes(dataKey)
  ) {
    return PERSONAL_GUARANTY_DATA_KEY[Number(dataKey)]
  }

  if (
    associatedPage === PAGE_LABEL_ADDITIONAL_QUESTIONS &&
    Object.keys(ADDITIONAL_QUESTIONS_DATA_KEY).includes(dataKey)
  ) {
    return ADDITIONAL_QUESTIONS_DATA_KEY[Number(dataKey)]
  }
}

export const compareValuesFromConditionals = (
  value: string,
  conditionalValue: string,
  isDataKey = false,
) => {
  // This Method Compares Values From MultiSelect Fields and Dropdowns
  // For Dropdowns it compares the value directly
  // For MultiSelect Fields it compares the values after sorting them.
  // The expected format for this is a string of comma separated values
  // For DataKey it checks if the value is included in the list of values
  if (!value || !conditionalValue) {
    return false
  }
  // Direct String Comparison
  if (value === conditionalValue) {
    return true
  }
  const valueArray = value
    .split(",")
    .map((value: string) => value.trim())
    .sort()

  const conditionalValueArray = conditionalValue
    .split(",")
    .map((value: string) => value.trim())
    .sort()

  // With Data Key Check if it includes the value
  if (isDataKey) {
    if (conditionalValueArray.includes(value.trim())) {
      return true
    }
  }

  // Compare sorted list
  if (JSON.stringify(conditionalValueArray) === JSON.stringify(valueArray)) {
    return true
  }

  return false
}

export const hasPassedConditions = (
  field: TemplateCustomField,
  customFieldData: { [key: string]: CustomFieldResponse } | undefined,
  dataKeyData: { [key: string]: any } | undefined,
  associatedPage: string,
) => {
  // Determine whether a conditional question will be displayed
  if (field && field.conditions && field.conditions.length > 0) {
    for (const condition of field.conditions) {
      if (condition.conditionCustomField) {
        if (
          customFieldData &&
          customFieldData[condition.conditionCustomField] &&
          compareValuesFromConditionals(
            customFieldData[condition.conditionCustomField].response as string,
            condition.value as string,
          )
        ) {
          return true
        }
      }
      if (condition.dataKey) {
        const dataKeyName = getDataKeyName(condition.dataKey, associatedPage)
        if (
          dataKeyName &&
          dataKeyData &&
          dataKeyData[dataKeyName] &&
          compareValuesFromConditionals(
            dataKeyData[dataKeyName],
            condition.value as string,
            true,
          )
        ) {
          return true
        }
      }
    }
  }
  return false
}

export const addConditionalValidationstoCustomSchema = (
  template: ApplicationTemplate,
  customSchema: yup.AnyObjectSchema,
  data: Application["data"],
  associatedPage: string,
) => {
  template.customFields
    ?.filter((field) => field.associatedPage === associatedPage)
    .filter((field) => field.required && !!field.id)
    .filter((field) => hasConditions(field))
    .filter((field) =>
      hasPassedConditions(field, data["customFields"], data, associatedPage),
    )
    .forEach((field) => {
      if (field.fieldType === "file") {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup.mixed().required("Please upload a file"),
          }),
        )
      } else {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup
              .object({
                response: yup.string().required("Field is required"),
              })
              .required("Field is required"),
          }),
        )
      }
    })

  return customSchema
}

export const isPersonalApplication = (data: Application["data"]) => {
  if (
    data &&
    data.applicantType &&
    data.applicantType === APPLICANT_TYPES.INDIVIDUAL_CONSUMER_HOMEOWNER
  ) {
    return true
  }
  return false
}

export const getSVGIcon = (label: string) => {
  if (label.includes("Application Started"))
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 22.75H8C4.35 22.75 2.25 20.65 2.25 17V7C2.25 3.35 4.35 1.25 8 1.25H16C19.65 1.25 21.75 3.35 21.75 7V17C21.75 20.65 19.65 22.75 16 22.75ZM8 2.75C5.14 2.75 3.75 4.14 3.75 7V17C3.75 19.86 5.14 21.25 8 21.25H16C18.86 21.25 20.25 19.86 20.25 17V7C20.25 4.14 18.86 2.75 16 2.75H8Z"
          fill="black"
        />
        <path
          d="M18.5 9.25H16.5C14.98 9.25 13.75 8.02 13.75 6.5V4.5C13.75 4.09 14.09 3.75 14.5 3.75C14.91 3.75 15.25 4.09 15.25 4.5V6.5C15.25 7.19 15.81 7.75 16.5 7.75H18.5C18.91 7.75 19.25 8.09 19.25 8.5C19.25 8.91 18.91 9.25 18.5 9.25Z"
          fill="black"
        />
        <path
          d="M12 13.75H8C7.59 13.75 7.25 13.41 7.25 13C7.25 12.59 7.59 12.25 8 12.25H12C12.41 12.25 12.75 12.59 12.75 13C12.75 13.41 12.41 13.75 12 13.75Z"
          fill="black"
        />
        <path
          d="M16 17.75H8C7.59 17.75 7.25 17.41 7.25 17C7.25 16.59 7.59 16.25 8 16.25H16C16.41 16.25 16.75 16.59 16.75 17C16.75 17.41 16.41 17.75 16 17.75Z"
          fill="black"
        />
      </svg>
    )
  else if (label === "Signed" || label === "Awaiting Signature") {
    const svg = (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.98 13.1999C16.79 13.1999 16.59 13.1199 16.45 12.9799L11.05 7.56994C10.8 7.31994 10.76 6.93994 10.95 6.63994L12.64 3.96994C13.11 3.22994 13.82 2.76994 14.65 2.67994C15.58 2.58994 16.54 2.94994 17.29 3.69994L20.32 6.72994C21.04 7.44994 21.4 8.41994 21.29 9.37994C21.2 10.2199 20.75 10.9499 20.05 11.3899L17.38 13.0799C17.26 13.1599 17.12 13.1999 16.98 13.1999ZM12.53 6.93994L17.09 11.4999L19.26 10.1299C19.57 9.92994 19.77 9.60994 19.81 9.21994C19.87 8.71994 19.66 8.18994 19.27 7.79994L16.24 4.76994C15.81 4.33994 15.29 4.10994 14.82 4.17994C14.44 4.21994 14.13 4.42994 13.91 4.77994L12.53 6.93994Z"
          fill="black"
        />
        <path
          d="M5.89001 21.34C5.00001 21.34 4.20001 21.02 3.60001 20.43C2.90001 19.73 2.59001 18.76 2.72001 17.68L3.70001 9.35003C4.00001 6.83003 5.11001 5.91003 7.70001 6.06003L11.62 6.29003C12.03 6.32003 12.35 6.67003 12.32 7.08003C12.29 7.49003 11.94 7.81003 11.53 7.78003L7.61001 7.56003C5.83001 7.45003 5.39001 7.80003 5.19001 9.53003L4.21001 17.85C4.14001 18.46 4.30001 19 4.66001 19.36C5.03001 19.72 5.57001 19.89 6.18001 19.81L14.5 18.83C16.25 18.62 16.64 18.16 16.47 16.44L16.23 12.49C16.21 12.08 16.52 11.72 16.93 11.7C17.34 11.67 17.7 11.99 17.72 12.4L17.95 16.32C18.19 18.81 17.21 20.01 14.66 20.32L6.34001 21.3C6.20001 21.33 6.04001 21.34 5.89001 21.34Z"
          fill="black"
        />
        <path
          d="M4.60999 20.1699C4.41999 20.1699 4.22999 20.0999 4.07999 19.9499C3.78999 19.6599 3.78999 19.1799 4.07999 18.8899L7.11999 15.8499C7.40999 15.5599 7.88999 15.5599 8.17999 15.8499C8.46999 16.1399 8.46999 16.6199 8.17999 16.9099L5.13999 19.9499C4.98999 20.0999 4.79999 20.1699 4.60999 20.1699Z"
          fill="black"
        />
      </svg>
    )
    return label === "Signed" ? (
      svg
    ) : (
      <span style={{ margin: "11.5px" }}>{svg}</span>
    )
  } else if (label.includes("Requested"))
    //  Signature Requested & Additional Data Requested
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.06 15.2499C11.65 15.2499 11.31 14.9099 11.31 14.4999V9.49988C11.31 9.08988 11.65 8.74988 12.06 8.74988C12.47 8.74988 12.81 9.08988 12.81 9.49988V14.4999C12.81 14.9099 12.47 15.2499 12.06 15.2499Z"
          fill="black"
        />
        <path
          d="M14.5 12.7499H9.5C9.09 12.7499 8.75 12.4099 8.75 11.9999C8.75 11.5899 9.09 11.2499 9.5 11.2499H14.5C14.91 11.2499 15.25 11.5899 15.25 11.9999C15.25 12.4099 14.91 12.7499 14.5 12.7499Z"
          fill="black"
        />
        <path
          d="M8.85002 22.11C6.38002 22.11 4.75002 21.57 3.73002 20.41C2.55002 19.08 2.36001 17.09 2.32001 15.32L2.19002 9.14998C2.09002 4.41998 3.66002 2.44998 7.76002 2.13998L15.8 1.97998C15.82 1.97998 15.83 1.97998 15.84 1.97998C19.95 2.11998 21.59 4.01998 21.69 8.75998L21.81 14.93C21.85 16.7 21.73 18.7 20.61 20.07C19.59 21.32 17.87 21.93 15.2 21.99L9.20001 22.11C9.08001 22.11 8.96002 22.11 8.85002 22.11ZM15.8 3.46998L7.83001 3.62998C5.03001 3.84998 3.60002 4.74998 3.69002 9.11998L3.82001 15.29C3.86001 17.41 4.17001 18.65 4.85001 19.42C5.61001 20.28 6.98001 20.66 9.18002 20.61L15.18 20.49C17.37 20.45 18.73 20.01 19.46 19.12C20.11 18.33 20.37 17.08 20.32 14.96L20.2 8.78998C20.1 4.42998 18.64 3.56998 15.8 3.46998Z"
          fill="black"
        />
      </svg>
    )
  else if (label === "Client Updated")
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 12.75C8.83 12.75 6.25 10.17 6.25 7C6.25 3.83 8.83 1.25 12 1.25C15.17 1.25 17.75 3.83 17.75 7C17.75 10.17 15.17 12.75 12 12.75ZM12 2.75C9.66 2.75 7.75 4.66 7.75 7C7.75 9.34 9.66 11.25 12 11.25C14.34 11.25 16.25 9.34 16.25 7C16.25 4.66 14.34 2.75 12 2.75Z"
          fill="black"
        />
        <path
          d="M3.41016 22.75C3.00016 22.75 2.66016 22.41 2.66016 22C2.66016 17.73 6.85015 14.25 12.0002 14.25C13.0102 14.25 14.0001 14.38 14.9601 14.65C15.3601 14.76 15.5902 15.17 15.4802 15.57C15.3702 15.97 14.9601 16.2 14.5602 16.09C13.7402 15.86 12.8802 15.75 12.0002 15.75C7.68015 15.75 4.16016 18.55 4.16016 22C4.16016 22.41 3.82016 22.75 3.41016 22.75Z"
          fill="black"
        />
        <path
          d="M18 22.75C16.34 22.75 14.78 21.87 13.94 20.44C13.49 19.72 13.25 18.87 13.25 18C13.25 16.54 13.9 15.19 15.03 14.29C15.87 13.62 16.93 13.25 18 13.25C20.62 13.25 22.75 15.38 22.75 18C22.75 18.87 22.51 19.72 22.06 20.45C21.81 20.87 21.49 21.25 21.11 21.57C20.28 22.33 19.17 22.75 18 22.75ZM18 14.75C17.26 14.75 16.56 15 15.97 15.47C15.2 16.08 14.75 17.01 14.75 18C14.75 18.59 14.91 19.17 15.22 19.67C15.8 20.65 16.87 21.25 18 21.25C18.79 21.25 19.55 20.96 20.13 20.44C20.39 20.22 20.61 19.96 20.77 19.68C21.09 19.17 21.25 18.59 21.25 18C21.25 16.21 19.79 14.75 18 14.75Z"
          fill="black"
        />
        <path
          d="M17.4299 19.7401C17.2399 19.7401 17.0499 19.6701 16.8999 19.5201L15.9099 18.5301C15.6199 18.2401 15.6199 17.76 15.9099 17.47C16.1999 17.18 16.6799 17.18 16.9699 17.47L17.4499 17.9501L19.0499 16.47C19.3499 16.19 19.8299 16.2101 20.1099 16.5101C20.3899 16.8101 20.3699 17.2901 20.0699 17.5701L17.9399 19.54C17.7899 19.67 17.6099 19.7401 17.4299 19.7401Z"
          fill="black"
        />
      </svg>
    )
  else if (label.includes("Reference") && label.includes("Completed"))
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
          fill="#292D32"
        />
        <path
          d="M16.9999 12.59H14.3199C13.2299 12.59 12.3799 11.74 12.3799 10.65V9.15997C12.3799 8.06997 13.2299 7.21997 14.3199 7.21997H15.8099C16.8799 7.21997 17.7499 8.08997 17.7499 9.15997V11.83C17.7499 12.25 17.4099 12.59 16.9999 12.59ZM14.3199 8.71997C13.9999 8.71997 13.8799 8.95997 13.8799 9.15997V10.65C13.8799 10.85 13.9899 11.09 14.3199 11.09H16.2499V9.16998C16.2499 8.92998 16.0499 8.72998 15.8099 8.72998H14.3199V8.71997Z"
          fill="#292D32"
        />
        <path
          d="M14.91 16.7801C14.65 16.7801 14.4 16.65 14.26 16.41C14.05 16.05 14.17 15.5901 14.52 15.3801C15.88 14.5701 16.25 14.3601 16.25 11.8401C16.25 11.4301 16.59 11.0901 17 11.0901C17.41 11.0901 17.75 11.4301 17.75 11.8401C17.75 14.9201 17.08 15.61 15.29 16.67C15.17 16.74 15.04 16.7801 14.91 16.7801Z"
          fill="#292D32"
        />
        <path
          d="M10.8602 12.59H8.18023C7.09023 12.59 6.24023 11.74 6.24023 10.65V9.15997C6.24023 8.06997 7.09023 7.21997 8.18023 7.21997H9.67023C10.7402 7.21997 11.6102 8.08997 11.6102 9.15997V11.83C11.6102 12.25 11.2802 12.59 10.8602 12.59ZM8.19023 8.71997C7.87023 8.71997 7.75023 8.95997 7.75023 9.15997V10.65C7.75023 10.85 7.86023 11.09 8.19023 11.09H10.1202V9.16998C10.1202 8.92998 9.92023 8.72998 9.68023 8.72998H8.19023V8.71997Z"
          fill="#292D32"
        />
        <path
          d="M8.76982 16.7801C8.50982 16.7801 8.25982 16.65 8.11982 16.41C7.90982 16.05 8.02982 15.5901 8.37982 15.3801C9.73982 14.5701 10.1098 14.3601 10.1098 11.8401C10.1098 11.4301 10.4498 11.0901 10.8598 11.0901C11.2698 11.0901 11.6098 11.4301 11.6098 11.8401C11.6098 14.9201 10.9398 15.61 9.14982 16.67C9.02982 16.74 8.89982 16.7801 8.76982 16.7801Z"
          fill="#292D32"
        />
      </svg>
    )
  else if (label === "Approved for Credit")
    return (
      <span style={{ margin: "11.5px" }}>
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="36" height="36" rx="24" fill="#20BF6B" />
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 3C9.735 3 3 9.735 3 18C3 26.265 9.735 33 18 33C26.265 33 33 26.265 33 18C33 9.735 26.265 3 18 3ZM25.17 14.55L16.665 23.055C16.455 23.265 16.17 23.385 15.87 23.385C15.57 23.385 15.285 23.265 15.075 23.055L10.83 18.81C10.395 18.375 10.395 17.655 10.83 17.22C11.265 16.785 11.985 16.785 12.42 17.22L15.87 20.67L23.58 12.96C24.015 12.525 24.735 12.525 25.17 12.96C25.605 13.395 25.605 14.1 25.17 14.55Z"
              fill="white"
            />
          </svg>
        </svg>
      </span>
    )
  else if (label === "Application Rejected")
    return (
      <span style={{ margin: "11.5px" }}>
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="36" height="36" rx="24" fill="#E74C3C" />
          <path
            d="M18 3C9.735 3 3 9.735 3 18C3 26.265 9.735 33 18 33C26.265 33 33 26.265 33 18C33 9.735 26.265 3 18 3ZM24.485 23.485C24.875 23.875 24.875 24.505 24.485 24.895C24.095 25.285 23.465 25.285 23.075 24.895L18 19.82L12.925 24.895C12.535 25.285 11.905 25.285 11.515 24.895C11.125 24.505 11.125 23.875 11.515 23.485L16.59 18.41L11.515 13.335C11.125 12.945 11.125 12.315 11.515 11.925C11.905 11.535 12.535 11.535 12.925 11.925L18 16.99L23.075 11.925C23.465 11.535 24.095 11.535 24.485 11.925C24.875 12.315 24.875 12.945 24.485 13.335L19.41 18.41L24.485 23.485Z"
            fill="white"
          />
        </svg>
      </span>
    )
  else if (label === "Manual Application Completed") {
    return (
      <svg
        width="24"
        height="24"
        version="1.1"
        viewBox="0 0 1200 1200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="m668.88 242.04c66.074 0.39453 129.38 26.562 176.45 72.934 47.066 46.375 74.172 109.29 75.547 175.35v27.719l27 6.6016c48.148 12.059 90.246 41.258 118.41 82.129 28.168 40.871 40.465 90.605 34.594 139.89-5.8711 49.285-29.512 94.742-66.492 127.85-36.977 33.105-84.758 51.598-134.39 52.008h-600c-45.207-0.023437-89.125-15.062-124.86-42.754s-61.254-66.469-72.555-110.24c-11.305-43.77-7.75-90.055 10.109-131.59 17.855-41.531 49-75.953 88.539-97.863l17.879-9.9609 0.60156-20.398c1.4219-48.023 25.043-92.672 63.949-120.87 38.906-28.191 88.691-36.738 134.77-23.133l25.441 7.4414 14.641-22.078-0.003907-0.003906c22.996-34.742 54.246-63.246 90.961-82.953 36.711-19.703 77.734-30 119.4-29.965m0-36c-47.543 0.023438-94.34 11.812-136.22 34.324-41.879 22.508-77.531 55.035-103.78 94.676-37.5-11.129-77.512-10.473-114.62 1.8828-37.113 12.352-69.535 35.809-92.883 67.195-23.348 31.383-36.488 69.184-37.652 108.28-37.496 20.766-68.742 51.195-90.5 88.125-21.758 36.93-33.227 79.012-33.219 121.88 0 42.129 11.09 83.516 32.152 120 21.066 36.484 51.363 66.781 87.848 87.848 36.484 21.062 77.871 32.152 120 32.152h600c42.129 0 83.516-11.09 120-32.152 36.484-21.066 66.781-51.363 87.848-87.848 21.062-36.484 32.152-77.871 32.152-120-0.035156-53.668-18.055-105.78-51.188-148-33.129-42.223-79.453-72.125-131.57-84.922-1.1328-75.633-31.977-147.78-85.863-200.86-53.887-53.078-126.5-82.828-202.14-82.816z"
            fill="#000"
            stroke="black"
            style={{ strokeWidth: "30px" }}
          />
          <path
            d="m411.96 623.64 24.117-24.242 101.88 101.76 201.96-201.96 24.117 24.121-226.08 226.2z"
            fill="#000"
            stroke="black"
            style={{ strokeWidth: "30px" }}
          />
          <path
            d="m411.96 623.64 24.117-24.242 101.88 101.76 201.96-201.96 24.117 24.121-226.08 226.2z"
            fill="#000"
            stroke="black"
            style={{ strokeWidth: "30px" }}
          />
        </g>
      </svg>
    )
  } else
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="10" stroke="black" strokeWidth="2" />
      </svg>
    )
}
