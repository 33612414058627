import { Business } from "src/types"

export const BUSINESS_PREFERENCES = {
  SEND_APPROVAL_EMAIL_DEFAULT: "sendApprovalEmailsDefault",
  HIDE_SENSITIVE_FIELDS_IN_PDF: "hideSensitiveFieldsInPdf",
}

export const usePreference = (key: string, business?: Business) => {
  // TODO: needs to be able to pull from UserToBusiness
  const biz = business

  return {
    preference: biz?.preferences?.[key],
  }
}
