import {
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import { Formik, FormikProps } from "formik"
import {
  OWNER_CONFIGS,
  PAGE_LABEL_USER_DETAILS,
  USER_CONFIG_APPLICANT_TYPE,
  USER_CONFIG_BUSINESS_TYPE,
  USER_CONFIG_BUSINESS_TYPE_LIST,
  USER_CONFIG_INDIVIDUAL_CONSUMER_HOMEOWNER,
} from "src/sections/@dashboard/intake_sections/constants"
import { ApplicationTemplate, Config, Page } from "src/types"
import { getConfigFromPage, getPageFromTemplate } from "src/utils/utils"
import { usePatchCreditApplicationTemplatePage } from "src/queries/internal/usePatchCreditApplicationTemplatePage"
import { enqueueSnackbar } from "notistack"

export default function CreditSectionUserDetails({
  applicationTemplate,
  refetch,
}: {
  applicationTemplate: ApplicationTemplate
  refetch: () => void
}) {
  const userDetailsPage = getPageFromTemplate(
    applicationTemplate,
    PAGE_LABEL_USER_DETAILS,
  )
  const { execute } = usePatchCreditApplicationTemplatePage(
    applicationTemplate.id || "",
    () => {
      enqueueSnackbar("Application Template Updated", { variant: "success" })
    },
  )

  const getConfigFormControlValueField = (
    value: boolean,
    name: string,
    label: string,
    props: FormikProps<Page>,
  ) => {
    return (
      <FormControlLabel
        control={
          <Switch
            size="small"
            name={name}
            checked={value}
            onChange={(event) => {
              const config = getConfigFromPage(props.values, label) as Config
              if (config) {
                props.setFieldValue(
                  "config",
                  props.values.config.map((c) => {
                    if (c.label === label) {
                      c.value = event.target.checked
                    }
                    return c
                  }),
                )
              } else {
                props.values.config.push({
                  label: label,
                  description: label,
                  value: event.target.checked,
                })
              }
            }}
          />
        }
        label={label + " Config Value"}
      />
    )
  }

  const getConfigFormControlRequiredField = (
    value: boolean,
    name: string,
    label: string,
    props: FormikProps<Page>,
  ) => {
    return (
      <FormControlLabel
        control={
          <Switch
            size="small"
            name={name}
            checked={value}
            onChange={(event) => {
              const config = getConfigFromPage(props.values, label) as Config
              if (config) {
                props.setFieldValue(
                  "config",
                  props.values.config.map((c) => {
                    if (c.label === label) {
                      c.required = event.target.checked
                    }
                    return c
                  }),
                )
              } else {
                props.values.config.push({
                  label: label,
                  description: label,
                  required: event.target.checked,
                  value: true,
                })
              }
            }}
          />
        }
        label={label + " Config Required"}
      />
    )
  }

  const getConfigFormControlListField = (
    value: string[],
    name: string,
    label: string,
    props: FormikProps<Page>,
  ) => {
    return (
      <TextField
        name={name}
        label={label}
        value={value ? (value.length > 0 ? value.join(",") : "") : ""}
        onChange={(event) => {
          const config = getConfigFromPage(props.values, label) as Config
          let value: string[] = []
          if (event.target.value) {
            if (event.target.value.split(",").length > 0) {
              value = event.target.value.split(",")
            } else {
              value = [event.target.value]
            }
          }

          if (config) {
            props.setFieldValue(
              "config",
              props.values.config.map((c) => {
                if (c.label === label) {
                  c.value = value
                }
                return c
              }),
            )
          } else {
            props.values.config.push({
              label: label,
              value: value,
            })
          }
        }}
      />
    )
  }

  return (
    <>
      <Paper elevation={3} sx={{ marginY: 4, p: 1 }}>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          {PAGE_LABEL_USER_DETAILS} Section Configuration
        </Typography>
        <Formik
          initialValues={userDetailsPage ? userDetailsPage : ({} as Page)}
          onSubmit={(values) => {
            execute(values, () => {
              refetch()
            })
          }}
        >
          {(props) => (
            <FormControl component="fieldset" variant="standard">
              <Stack spacing={3}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="enabled"
                      checked={props.values.enabled}
                      onChange={props.handleChange}
                    />
                  }
                  label="Enable Section"
                />
                {getConfigFormControlValueField(
                  getConfigFromPage(props.values, USER_CONFIG_APPLICANT_TYPE)
                    ?.value as boolean,
                  "applicantTypeValue",
                  USER_CONFIG_APPLICANT_TYPE,
                  props,
                )}
                {getConfigFormControlRequiredField(
                  getConfigFromPage(props.values, USER_CONFIG_APPLICANT_TYPE)
                    ?.required as boolean,
                  "applicantTypeRequired",
                  USER_CONFIG_APPLICANT_TYPE,
                  props,
                )}
                {getConfigFormControlValueField(
                  getConfigFromPage(props.values, USER_CONFIG_BUSINESS_TYPE)
                    ?.value as boolean,
                  "businessTypeValue",
                  USER_CONFIG_BUSINESS_TYPE,
                  props,
                )}
                {getConfigFormControlRequiredField(
                  getConfigFromPage(props.values, USER_CONFIG_BUSINESS_TYPE)
                    ?.required as boolean,
                  "businessTypeRequired",
                  USER_CONFIG_BUSINESS_TYPE,
                  props,
                )}
                {getConfigFormControlValueField(
                  getConfigFromPage(
                    props.values,
                    USER_CONFIG_INDIVIDUAL_CONSUMER_HOMEOWNER,
                  )?.value as boolean,
                  "IndividualConsumerHomeownerRequired",
                  USER_CONFIG_INDIVIDUAL_CONSUMER_HOMEOWNER,
                  props,
                )}
                {getConfigFormControlListField(
                  getConfigFromPage(
                    props.values,
                    USER_CONFIG_BUSINESS_TYPE_LIST,
                  )?.value as string[],
                  "businessTypeList",
                  USER_CONFIG_BUSINESS_TYPE_LIST,
                  props,
                )}
              </Stack>
              <Stack sx={{ marginY: 2 }}>
                <Button
                  variant="contained"
                  // disabled={isLoading}
                  onClick={() => {
                    props.handleSubmit()
                  }}
                >
                  Save
                </Button>
              </Stack>
            </FormControl>
          )}
        </Formik>
      </Paper>
    </>
  )
}
