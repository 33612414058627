import { ComponentType } from "react"
import { useRoutes, Navigate } from "react-router-dom"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import DashboardLayout from "./layouts/dashboard/DashboardLayout"

import Page404 from "./pages/Page404"
import BusinessOnboarding from "./pages/BusinessOnboarding"
import NewUserPage from "./pages/NewUserPage"
import CreditApplicationListPage from "./pages/CreditApplicationListPage"
import CreditApplicationDetailsView from "./sections/@dashboard/credit/view/CreditApplicationDetailsView"
import {
  NoAuthReferenceSimpleLayout,
  NoAuthSimpleLayout,
} from "./layouts/dashboard"
import AnonymousTradeCreditPage from "./pages/AnonymousTradeCreditPage"
import QuecanPage from "./pages/QuecanPage"
import CreditApplicationConfigView from "./sections/@dashboard/credit/view/CreditApplicationConfigView"
import NoLogoSimpleLayout from "./layouts/simple/NoLogoSimpleLayout"
import BaseApplication from "./pages/BaseApplication"
import TradeReferenceIntake from "./pages/TradeReferenceIntake"
import ProvideAdditionalDataPage from "./pages/ProvideAdditionalDataPage"
import { useUser } from "./queries"
import { CircularProgress } from "@mui/material"
import Auth0Redirect from "./pages/Auth0Redirect"
import ManualApplication from "./sections/@dashboard/credit/ManualApplication"
import PublicComplete from "./pages/PublicComplete"
import CashApplication from "./pages/CashApplication"
import CashApplicationListPage from "./pages/CashApplicationListPage"
import CashApplicationDetailsView from "./sections/@dashboard/cod/view/CashApplicationDetailsView"
import InternalDashboard from "./layouts/internal/InternalDashboard"
import { INTERNAL_EMAIL_ENDING, getInternalChildrenRoutes } from "./statics"

const ProtectedRoute = ({
  component,
  ...args
}: {
  component: ComponentType
}) => {
  const Component = withAuthenticationRequired(component, args)
  return <Component />
}

export default function Router() {
  const PRODUCTION = "production"
  const INTERNAL = "internal"
  const user = useUser()
  const applicationRoutes = [
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/applications" />, index: true },
        // onboarding pages
        {
          path: "/onboarding/user",
          element: <ProtectedRoute component={NewUserPage} />,
        },
        {
          path: "/onboarding/business",
          element: <ProtectedRoute component={BusinessOnboarding} />,
        },
        {
          path: "/settings",
          element: <ProtectedRoute component={CreditApplicationConfigView} />,
        },
        {
          path: "applications",
          children: [
            {
              element: <ProtectedRoute component={CreditApplicationListPage} />,
              index: true,
            },
            user.isCreditManager
              ? {
                  path: ":id",
                  element: (
                    <ProtectedRoute component={CreditApplicationDetailsView} />
                  ),
                }
              : {
                  path: ":id",
                  element: <CircularProgress />,
                },
          ],
        },
        {
          path: "cash-applications",
          children: [
            {
              element: <ProtectedRoute component={CashApplicationListPage} />,
              index: true,
            },
            {
              path: ":id",
              element: (
                <ProtectedRoute component={CashApplicationDetailsView} />
              ),
            },
          ],
        },
        // {
        //   path: "monitoring",
        //   children: [
        //     {
        //       element: <ProtectedRoute component={CreditMonitorsListPage} />,
        //       index: true,
        //     },
        //   ],
        // },
      ],
    },

    {
      path: "/trade-credit",
      element: <NoLogoSimpleLayout />,
      children: [
        { element: <Navigate to="/trade-credit" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
        {
          path: "base",
          element: <ProtectedRoute component={BaseApplication} />,
        },
        {
          path: ":id",
          element: <ProtectedRoute component={BaseApplication} />,
        },

        {
          path: "redirect",
          element: <ProtectedRoute component={Auth0Redirect} />,
        },
        {
          path: ":applicationId/request/:requestId",
          element: <ProtectedRoute component={ProvideAdditionalDataPage} />,
        },
      ],
    },

    {
      path: "/manual-application",
      element: <DashboardLayout />,
      children: [
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
        {
          path: ":id",
          element: <ProtectedRoute component={ManualApplication} />,
        },
      ],
    },

    {
      path: "/ua-trade-credit",
      element: <NoAuthSimpleLayout />,
      children: [
        { element: <Navigate to="/ua-trade-credit" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
        {
          path: "base",
          element: <BaseApplication />,
        },
        {
          path: "complete",
          element: <PublicComplete />,
        },
      ],
    },

    {
      path: "/ua-cod",
      element: <NoAuthSimpleLayout />,
      children: [
        { element: <Navigate to="/ua-cod" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
        {
          path: "base",
          element: <CashApplication />,
        },
        {
          path: "complete",
          element: <PublicComplete />,
        },
        // {
        //   path: ":id",
        //   element: <BaseApplication />,
        // },
      ],
    },

    {
      path: "/cod",
      element: <NoLogoSimpleLayout />,
      children: [
        { element: <Navigate to="/cod" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
        {
          path: "base",
          element: <ProtectedRoute component={CashApplication} />,
        },
        {
          path: ":id",
          element: <ProtectedRoute component={CashApplication} />,
        },

        {
          path: "redirect",
          element: <ProtectedRoute component={Auth0Redirect} />,
        },
      ],
    },

    {
      path: "/trade-reference",
      element: <NoAuthReferenceSimpleLayout />,
      children: [
        { element: <Navigate to="/trade-reference" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
        {
          path: ":id",
          element: <TradeReferenceIntake />,
        },
      ],
    },

    {
      path: "/form",
      element: <NoAuthSimpleLayout />,
      children: [
        { element: <Navigate to="/form" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
        {
          path: ":id",
          element: <AnonymousTradeCreditPage />,
        },
        {
          path: "quecan",
          element: <QuecanPage />,
        },
      ],
    },
    {
      path: "internal",
      element: <ProtectedRoute component={InternalDashboard} />,
      children:
        user &&
        user.isNetNowAdmin &&
        user.data &&
        user.data.email &&
        user.data.email.endsWith(INTERNAL_EMAIL_ENDING) &&
        process.env.REACT_APP_ENV !== PRODUCTION
          ? getInternalChildrenRoutes(ProtectedRoute)
          : [
              {
                path: "*",
                element: <Page404 />,
              },
            ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
    {
      path: "404",
      element: <Page404 />,
    },
  ]

  const internalRoutes = [
    {
      path: "/",
      element: <ProtectedRoute component={InternalDashboard} />,
      children:
        user &&
        user.isNetNowAdmin &&
        user.data &&
        user.data.email &&
        user.data.email.endsWith(INTERNAL_EMAIL_ENDING)
          ? getInternalChildrenRoutes(ProtectedRoute)
          : [
              {
                path: "*",
                element: <Page404 />,
              },
            ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
    {
      path: "404",
      element: <Page404 />,
    },
  ]

  const getRoutes = () => {
    if (process.env.REACT_APP_ENV === INTERNAL) {
      return internalRoutes
    }
    return applicationRoutes
  }
  const router = useRoutes(getRoutes())
  return router
}
