import * as yup from "yup"
import { Application, ApplicationTemplate } from "src/types"
import { APPLICANT_TYPES } from "src/statics"
import {
  addConditionalValidationstoCustomSchema,
  hasConditions,
} from "../../utils"
import {
  PAGE_LABEL_USER_DETAILS,
  USER_CONFIG_BUSINESS_TYPE,
  USER_CONFIG_INDIVIDUAL_CONSUMER_HOMEOWNER,
} from "../constants"
import { isSubSectionEnabled } from "../template_helpers"

const AUTHENTICATED_USER_DETAILS_YUP_SCHEMA = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().email("Email is required").required("Email is required"),
  userPhoneNumber: yup
    .string()
    .required()
    .matches(/\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/, "Phone number is not valid"),
  businessType: yup.string(),
})

export const getUserEnabledSchema = (
  template: ApplicationTemplate,
  data: Application["data"] = {},
): yup.AnyObjectSchema => {
  let enabledSchema = AUTHENTICATED_USER_DETAILS_YUP_SCHEMA
  template.formTemplate.pages
    .find((step) => step.label === PAGE_LABEL_USER_DETAILS)
    ?.config?.filter((config) => !!config.value)
    .forEach(
      (config) =>
        (enabledSchema = enabledSchema.concat(
          getAdditionalSchema(config.label, template),
        )),
    )
  let customSchema = yup.object({})
  template.customFields
    ?.filter((field) => field.associatedPage === PAGE_LABEL_USER_DETAILS)
    .filter((field) => field.required && !!field.id)
    .filter((field) => !hasConditions(field))
    .forEach((field) => {
      if (field.fieldType === "file") {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup.mixed().required("Please upload a file"),
          }),
        )
      } else {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup
              .object({
                response: yup.string().required("Field is required"),
              })
              .required("Field is required"),
          }),
        )
      }
    })

  if (data) {
    customSchema = addConditionalValidationstoCustomSchema(
      template,
      customSchema,
      data,
      PAGE_LABEL_USER_DETAILS,
    )
  }

  enabledSchema = enabledSchema.concat(
    yup.object({
      customFields: customSchema,
    }),
  )
  return enabledSchema
}

export const getIndividualConsumerHomeownerEnabled = (
  template?: ApplicationTemplate,
) => {
  const v = template?.formTemplate.pages
    .find((page) => page.label === PAGE_LABEL_USER_DETAILS)
    ?.config?.find((config) =>
      config.label.includes(USER_CONFIG_INDIVIDUAL_CONSUMER_HOMEOWNER),
    )?.value

  // by default it is enabled if not specified
  return v === undefined || Boolean(v)
}

const getAdditionalSchema = (label: string, template: ApplicationTemplate) => {
  switch (label) {
    case "Applicant Type":
      return yup.object({
        applicantType: yup.string().required("Applicant type is required"),
        businessType: yup.string().when("applicantType", {
          is: (fieldTwo: string) =>
            [
              APPLICANT_TYPES.LLC,
              APPLICANT_TYPES.CORPORATION,
              APPLICANT_TYPES.PARTNERSHIP,
              APPLICANT_TYPES.LIMITED_PARTNERSHIP,
              APPLICANT_TYPES.NON_PROFIT,
              APPLICANT_TYPES.SOLE_PROPRIETORSHIP,
            ].includes(fieldTwo) &&
            isSubSectionEnabled(
              template,
              PAGE_LABEL_USER_DETAILS,
              USER_CONFIG_BUSINESS_TYPE,
            ),
          then: yup.string().required(),
        }),
      })
    default:
      return yup.object({})
  }
}
