import {
  Button,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import { Formik } from "formik"
import { enqueueSnackbar } from "notistack"
import { usePatchInternalBusiness } from "src/queries/internal/usePatchInternalBusiness"
import { Business } from "src/types"
import { convertArrayToString } from "src/utils/utils"

export default function ClientBusinessDetails({
  id,
  data,
  refetch,
}: {
  id: string
  data: Business
  refetch: () => void
}) {
  const { isLoading, execute } = usePatchInternalBusiness(() => {
    enqueueSnackbar("Business updated", { variant: "success" })
  })
  return (
    <>
      <Container sx={{ p: 2 }}>
        <Formik
          initialValues={data as Business}
          onSubmit={(values) => {
            execute(values, () => {
              refetch()
            })
          }}
        >
          {(props) => (
            <Grid direction={"row"} container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  id="name"
                  name="name"
                  margin="normal"
                  fullWidth
                  label="Name"
                  type="text"
                  value={props.values.name}
                  onChange={props.handleChange}
                  error={Boolean(props.errors?.name)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="email"
                  name="email"
                  margin="normal"
                  fullWidth
                  label="Email"
                  type="text"
                  value={props.values.email}
                  onChange={props.handleChange}
                  error={Boolean(props.errors?.email)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="street"
                  name="street"
                  margin="normal"
                  fullWidth
                  label="street"
                  type="text"
                  value={props.values.street}
                  onChange={props.handleChange}
                  error={Boolean(props.errors?.state)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="city"
                  name="city"
                  margin="normal"
                  fullWidth
                  label="City"
                  type="text"
                  value={props.values.city}
                  onChange={props.handleChange}
                  error={Boolean(props.errors?.city)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="postalCode"
                  margin="normal"
                  name="postalCode"
                  fullWidth
                  label="Postal Code"
                  type="text"
                  value={props.values.postalCode}
                  onChange={props.handleChange}
                  error={Boolean(props.errors?.postalCode)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="state"
                  margin="normal"
                  fullWidth
                  label="State"
                  type="text"
                  value={props.values.state}
                  onChange={props.handleChange}
                  error={Boolean(props.errors?.state)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  margin="normal"
                  style={{ flex: "1 1 0px" }}
                  required
                >
                  <InputLabel id="country-label">Country</InputLabel>
                  <Select
                    labelId="country-label"
                    id="country"
                    label="Country"
                    name="country"
                    value={props.values.country}
                    onChange={props.handleChange}
                    error={Boolean(props.errors?.country)}
                  >
                    <MenuItem disabled value={undefined}>
                      Select one
                    </MenuItem>

                    {["United States", "Canada", "US", "CA"].map(
                      (value, index) => (
                        <MenuItem value={value} key={index}>
                          {value}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="shortenedUrl"
                  margin="normal"
                  fullWidth
                  label="Shortened Url"
                  type="text"
                  value={props.values.shortenedUrl}
                  onChange={props.handleChange}
                  error={Boolean(props.errors?.shortenedUrl)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  margin="normal"
                  style={{ flex: "1 1 0px" }}
                  required
                >
                  <InputLabel id="currency-label">Currency</InputLabel>
                  <Select
                    labelId="currency-label"
                    id="currency"
                    name="currency"
                    label="currency"
                    value={props.values.currency}
                    onChange={props.handleChange}
                    error={Boolean(props.errors?.currency)}
                  >
                    <MenuItem disabled value={undefined}>
                      Select one
                    </MenuItem>

                    {["USD", "CAD"].map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  margin="normal"
                  style={{ flex: "1 1 0px" }}
                  required
                >
                  <InputLabel id="language-label">Language</InputLabel>
                  <Select
                    labelId="language-label"
                    id="language"
                    name="language"
                    label="Language"
                    value={props.values.language}
                    onChange={props.handleChange}
                    error={Boolean(props.errors?.language)}
                  >
                    <MenuItem disabled value={undefined}>
                      Select one
                    </MenuItem>

                    {["es", "en", "fr"].map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="color"
                  name="color"
                  label="Color (in HEX)"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">#</InputAdornment>
                    ),
                  }}
                  value={props.values.color}
                  onChange={props.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="preferences"
                  name="preferences"
                  label="Preferences"
                  type="text"
                  multiline
                  rows={8}
                  fullWidth
                  value={convertArrayToString(
                    props.values.preferences as { [key: string]: string },
                  )}
                  onChange={props.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  disabled={isLoading}
                  onClick={() => {
                    props.handleSubmit()
                  }}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
          )}
        </Formik>
      </Container>
    </>
  )
}
