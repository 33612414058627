import { Form } from "formik"

import QontoStepper from "src/sections/@dashboard/components/QontoStepper"

import { Box, useTheme } from "@mui/material"

import TradeReferencesSection from "src/sections/@dashboard/intake_sections/TradeReferencesSection"
import { getTradeEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/TradeReferenceSectionSchema"

import BankReferenceSection from "src/sections/@dashboard/intake_sections/BankReferenceSection"
import { getBankEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/BankReferenceSectionSchema"

import OwnersSection from "src/sections/@dashboard/intake_sections/OwnersSection"
import { getOwnersEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/OwnersSectionSchema"

import CompanyDetailsSection from "src/sections/@dashboard/intake_sections/CompanyDetailsSection"
import { getCompanyDetailsEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/CompanyDetailsSectionSchema"

import PersonalGuarantySection from "src/sections/@dashboard/intake_sections/PersonalGuarantySection"
import { getPersonalGuarantyEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/PersonalGuarantySectionSchema"
import CustomQuestionsSection from "src/sections/@dashboard/intake_sections/CustomQuestionsSection"

import Complete from "../../intake_sections/Complete"
import { FormComponent } from "src/types/credit/FormComponent"
import { getAdditionalQuestionsSchema } from "../../intake_sections/schemas/CustomQuestionsSectionsSchema"
import { PAGE_LABEL_ADDITIONAL_QUESTIONS } from "../../intake_sections/constants"

export default ({
  steps,
  activeStep,
  handleBack,
  props,
  onContinue,
  onDataFieldUpdated,
  onCustomFieldUpdated,
  application,
  template,
}: FormComponent) => {
  const theme = useTheme()
  // const params = useParams()
  // const { id } = params

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        padding: "5%",
        maxHeight: "100vh",
        overflow: "auto",
      }}
    >
      <Box
        style={{ margin: "0 5% 32px 5%" }}
        display={{
          xs: "none",
          sm: "none",
          md: "block",
          large: "block",
          xlarge: "block",
        }}
      >
        <QontoStepper
          steps={steps}
          data={props.values.data}
          activeStep={activeStep}
          handleBack={handleBack}
          template={template}
        />
      </Box>
      <Box
        style={{
          textAlign: "left",
          padding: "32px 10% 32px 10%",
          border: "solid",
          borderWidth: "1px",
          borderRadius: "16px",
          borderColor: theme.palette.primary.main,
        }}
      >
        <Form>
          {/*
          {completionError && (
            <>
              <Typography
                component="h1"
                variant="h5"
                style={{ marginTop: "8px" }}
              >
                Error!
              </Typography>
              <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
                There was an error while completing your application. Please
                refresh this page. If the error persists, please contact us at
                info@netnow.io with the following information.
              </Typography>
              <Divider style={{ margin: "16px 0 16px 0" }} />
              <Typography color="error">{completionError.message}</Typography>
              <Typography color="error">Application ID: {id}</Typography>
              <Typography color="error">
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  completionError.response?.data?.error
                }
              </Typography>
            </>
          )} */}
          {activeStep == 99 && <Complete />}
          {steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("business") && (
              <CompanyDetailsSection
                application={application}
                data={props.values.data}
                errors={{
                  ...props.errors.data,
                  salesRep: props.errors.salesRep,
                }}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val)
                }}
                onCustomChange={function (
                  key: string,
                  value: string | File | undefined,
                ): void {
                  onCustomFieldUpdated(key, value)
                }}
                onContinue={() => {
                  onContinue(
                    getCompanyDetailsEnabledSchema(template, props.values.data),
                  )
                }}
                template={template}
              />
            )}
          {steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("owners") && (
              <OwnersSection
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val)
                }}
                onCustomChange={(
                  key: string,
                  value: string | File | undefined,
                ) => {
                  onCustomFieldUpdated(key, value)
                }}
                onContinue={() => {
                  onContinue(
                    getOwnersEnabledSchema(template, props.values.data),
                  )
                }}
              />
            )}
          {steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("trade") && (
              <TradeReferencesSection
                size={3}
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val)
                }}
                onContinue={() => {
                  onContinue(getTradeEnabledSchema(template, props.values.data))
                }}
                template={template}
              />
            )}
          {steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("bank") && (
              <BankReferenceSection
                application={application}
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val)
                }}
                onCustomChange={(
                  key: string,
                  value: string | File | undefined,
                ) => {
                  onCustomFieldUpdated(key, value)
                }}
                onContinue={() => {
                  onContinue(getBankEnabledSchema(template, props.values.data))
                }}
                template={template}
              />
            )}
          {steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("guaranty") && (
              <PersonalGuarantySection
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val)
                }}
                onContinue={() => {
                  onContinue(
                    getPersonalGuarantyEnabledSchema(
                      template,
                      props.values.data,
                    ),
                  )
                }}
                template={template}
              />
            )}

          {steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("additional") && (
              <CustomQuestionsSection
                application={application}
                errors={props.errors.data}
                data={props.values.data}
                onChange={function (
                  key: string,
                  value: string | File | undefined,
                ): void {
                  onCustomFieldUpdated(key, value)
                }}
                onContinue={function (): void {
                  onContinue(
                    getAdditionalQuestionsSchema(template, props.values.data),
                    undefined,
                    undefined,
                    PAGE_LABEL_ADDITIONAL_QUESTIONS,
                  )
                }}
              />
            )}
        </Form>
      </Box>
    </Box>
  )
}
