import { Button, Container, Divider } from "@mui/material"
import { Stack } from "@mui/system"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { useCreateInternalCreditApplicationTemplate } from "src/queries/internal/useCreateInternalCreditApplicationTemplate"
import { enqueueSnackbar } from "notistack"
import { confirm } from "src/components/confirm/"
import { CreateApplicationTemplate } from "src/types"
import EditCreditEmailNotes from "./components/EditCreditEmailNotes"
import EditCreditApplicationTemplate from "./components/EditCreditApplicationTemplate"
import CreditUrlPopulation from "./components/ApplicationTemplate/CreditUrlPopulation"
import CreditApplicationCard from "./components/ApplicationTemplate/CreditApplicationCard"

export default function ClientCreditDetails({ id }: { id: string }) {
  const {
    execute: executeCreateCreditApplication,
    isLoading: isLoadingCreateTemplate,
  } = useCreateInternalCreditApplicationTemplate(() => {
    enqueueSnackbar("Credit Application Template Created", {
      variant: "success",
    })
  })
  const {
    data,
    refetch,
    isLoading: isFetchingTemplate,
  } = useApplicationTemplate(id)


  return (
    <Container sx={{ p: 2 }}>
      {isFetchingTemplate && "Loading..."}
      {!isFetchingTemplate && !data && (
        <>
          <Stack sx={{ p: 1 }}>
            <Button
              variant="contained"
              disabled={isLoadingCreateTemplate}
              onClick={() => {
                confirm(
                  "Are you Sure you want to Activate Credit Applications For This Account?",
                )
                  .then(
                    () => {
                      executeCreateCreditApplication(
                        { seller: id } as CreateApplicationTemplate,
                        () => {
                          refetch()
                        },
                      )
                      return
                    },
                    () => {
                      console.log("error")
                    },
                  )
                  .catch(() => {
                    console.log("error")
                  })
              }}
            >
              Activate Credit Application
            </Button>
          </Stack>
        </>
      )}
      {data && (
        <>
          <CreditApplicationCard id={id as string} />
          <CreditUrlPopulation id={id as string} />
          <EditCreditApplicationTemplate
            applicationTemplate={data}
            refetch={refetch}
          />
          <EditCreditEmailNotes applicationTemplate={data} refetch={refetch} />
        </>
      )}
      <Divider />
    </Container>
  )
}
