import {
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import { Formik } from "formik"
import { enqueueSnackbar } from "notistack"
import { usePatchCashApplicationTemplatePage } from "src/queries/internal/usePatchCashApplicationTemplatePage"
import { PAGE_LABEL_USER_DETAILS } from "src/sections/@dashboard/intake_sections/constants"
import { ApplicationTemplate, Config, Page } from "src/types"
import { getConfigFromPage, getPageFromTemplate } from "src/utils/utils"

export default function CashSectionUserDetails({
  applicationTemplate,
  refetch,
}: {
  applicationTemplate: ApplicationTemplate
  refetch: () => void
}) {
  const userDetailsPage = getPageFromTemplate(
    applicationTemplate,
    PAGE_LABEL_USER_DETAILS,
  )

  const { execute, isLoading } = usePatchCashApplicationTemplatePage(
    applicationTemplate.id || "",
    () => {
      enqueueSnackbar("Application Template Updated", { variant: "success" })
    },
  )

  return (
    <>
      <Paper elevation={3} sx={{ marginY: 2, p: 1 }}>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          {PAGE_LABEL_USER_DETAILS} Section Configuration
        </Typography>
        <Formik
          initialValues={userDetailsPage ? userDetailsPage : ({} as Page)}
          onSubmit={(values) => {
            execute(values, () => {
              refetch()
            })
          }}
        >
          {(props) => (
            <FormControl component="fieldset" variant="standard">
              <Stack spacing={3}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="enabled"
                      checked={props.values.enabled}
                      onChange={props.handleChange}
                    />
                  }
                  label="Enabled Section"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="applicantType"
                      checked={
                        getConfigFromPage(props.values, "Applicant Type")
                          ?.value as boolean
                      }
                      onChange={(event) => {
                        const config = getConfigFromPage(
                          props.values,
                          "Applicant Type",
                        ) as Config
                        if (config) {
                          props.setFieldValue(
                            "config",
                            props.values.config.map((c) => {
                              if (c.label === "Applicant Type") {
                                c.value = event.target.checked
                              }
                              return c
                            }),
                          )
                        } else {
                          props.values.config.push({
                            label: "Applicant Type",
                            description: "Applicant Type",
                            value: event.target.checked,
                          })
                        }
                      }}
                    />
                  }
                  label="Applicant Type Config Value"
                />
              </Stack>
              <Stack sx={{ marginY: 2 }}>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  onClick={() => {
                    props.handleSubmit()
                  }}
                >
                  Save
                </Button>
              </Stack>
            </FormControl>
          )}
        </Formik>
      </Paper>
    </>
  )
}
