import queryString from "query-string"
import { useQuery } from "react-query"

type DomainInfo = {
  host: string
  debugMessage: string
  domainAgeFound: boolean
  domainRegistered: "yes" | "no"
  domainCreationDate: string // Format: "YYYY-MM-DD"
  domainAgeInDays: number
  domainAgeInMonths: number
  domainAgeInYears: number
  registrar: string
  registrarUrl: string
  registrarAbuseEmail: string
  registrarAbuseForm: string
  registrarTwitter: string
  ownerOrganization: string
  ownerCountry: string
  ownerStateProvince: string
  ownerEmailHash: string
  ownerEmailDomain: string
  ownerFreeEmail: boolean
  ownerPrivatePerson: boolean
  whoisDataHidden: boolean
  dnssec: "unsigned" | "signed"
  nameservers: {
    name: string
    ipv4: string
    ipv6: string
  }[]
  ip: string
  ipCountry: string
  ipIsp: string
  ipAsn: string
}

export function useValidateDomain(domain: string) {
  return useQuery<DomainInfo>(
    "/domain/validate?" +
      queryString.stringify({
        domain,
      }),
    {
      enabled: !!domain,
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )
}
