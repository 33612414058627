import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material"
import { TableHeadCustom } from "src/components/table"
import Label from "src/components/label"
import { useListInternalCashApplications } from "src/queries/internal/useListInternalCashApplications"
import { getStatusString } from "src/sections/@dashboard/utils"
import { fDateTime } from "src/utils/formatTime"
import { useDeleteInternalCashApplication } from "src/queries/internal/useDeleteInternalCashApplication"
import { confirm } from "src/components/confirm"
import { Stack } from "@mui/system"
import { APPLICATION_STAGE } from "src/statics"
import { useState } from "react"
import { ListInternalApplication } from "src/types"

export default function ViewCashApplicationDialog({
  open,
  onClose,
  businessId,
}: {
  open: boolean
  onClose: () => void
  businessId: string
}) {
  const { data, refetch } = useListInternalCashApplications(
    businessId as string,
  )

  const { execute } = useDeleteInternalCashApplication(() => {
    refetch()
  })

  const [stageQuery, setStageQuery] = useState<number | null>(null)
  const [searchQuery, setSearchQuery] = useState<string | null>(null)

  const filterData = (data: ListInternalApplication[]) => {
    let applicationList = data

    if (stageQuery) {
      applicationList = data?.filter((cashApplication) => {
        if (stageQuery === null) return true
        return cashApplication.stage === stageQuery
      })
    }

    if (searchQuery) {
      return applicationList.filter((application) => {
        return application.buyerName
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      })
    }

    return applicationList
  }

  const TABLE_HEAD = [
    { id: "name", label: "Business Name", align: "left" },
    { id: "date", label: "Date", align: "left" },
    { id: "status", label: "Status", align: "left" },
    { id: "action", label: "Action", align: "left" },
  ]
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={"lg"} fullWidth>
        <DialogTitle id="alert-dialog-title">Cash Applications</DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          <Stack sx={{ marginX: 2 }}>
            <TextField
              label="Search"
              margin="normal"
              fullWidth
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            {[
              APPLICATION_STAGE.APPROVED,
              APPLICATION_STAGE.COMPLETED_BY_CLIENT,
              APPLICATION_STAGE.AWAITING_SIGNATURE,
              APPLICATION_STAGE.MANUAL_COMPLETE,
              APPLICATION_STAGE.MANUAL_INCOMPLETE,
              APPLICATION_STAGE.ONE,
              APPLICATION_STAGE.REJECTED,
              APPLICATION_STAGE.ARCHIVE,
              null,
            ].map((stage, index) => {
              return (
                <Stack
                  key={index}
                  sx={{ mb: 2 }}
                  onClick={() => {
                    setStageQuery(stage)
                  }}
                >
                  <Label
                    variant="soft"
                    color={
                      (stage === APPLICATION_STAGE.APPROVED && "success") ||
                      (stage &&
                        stage < APPLICATION_STAGE.APPROVED &&
                        "warning") ||
                      (stage === APPLICATION_STAGE.REJECTED && "error") ||
                      "default"
                    }
                  >
                    {stage && getStatusString(stage)}
                    {null === stage && "All"}
                  </Label>
                </Stack>
              )
            })}
          </Stack>
          <TableContainer sx={{ mt: 3, overflow: "unset" }}>
            <Table sx={{ minWidth: 400 }}>
              <TableHeadCustom headLabel={TABLE_HEAD} />
              <TableBody>
                {data &&
                  filterData(data).map((cashApplication) => (
                    <TableRow key={cashApplication.id}>
                      <TableCell align="left">
                        {cashApplication.buyerName}
                      </TableCell>
                      <TableCell align="left">
                        {fDateTime(cashApplication.createdAt)}
                      </TableCell>
                      <TableCell align="left">
                        <Label
                          variant="soft"
                          color={
                            (cashApplication.stage ===
                              APPLICATION_STAGE.APPROVED &&
                              "success") ||
                            (cashApplication.stage <
                              APPLICATION_STAGE.APPROVED &&
                              "warning") ||
                            (cashApplication.stage ===
                              APPLICATION_STAGE.REJECTED &&
                              "error") ||
                            "default"
                          }
                        >
                          {getStatusString(cashApplication.stage)}
                        </Label>
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          variant="contained"
                          onClick={() => {
                            confirm(
                              `Are You Sure You Want To Delete This Application from ${cashApplication.buyerName}?`,
                            )
                              .then(() => {
                                execute(cashApplication)
                                return
                              })
                              .catch(() => {
                                return
                              })
                          }}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
