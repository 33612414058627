import { AddOutlined, DeleteOutline } from "@mui/icons-material"
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  IconButton,
} from "@mui/material"
import { Form, Formik, FormikProps } from "formik"
import { enqueueSnackbar } from "notistack"
import { TemplateCustomField } from "src/types"
import CustomFieldConfigView from "./CustomFieldConfigView"
import { useTemplateCustomFields } from "src/queries/credit/useTemplateCustomFields"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { MutableRefObject, useState } from "react"
import { confirm } from "src/components/confirm"
import { useDeleteTemplateCustomFields } from "src/queries/credit/useDeleteTemplateCustomFields"
import { useCreateTemplateCustomFields } from "src/queries/credit/useCreateTemplateCustomFields"
import { usePatchTemplateCustomFields } from "src/queries/credit/usePatchTemplateCustomFields"
import { info, error } from "src/utils/logger"

export default ({
  bagRef,
  businessId,
}: {
  bagRef: MutableRefObject<FormikProps<TemplateCustomField[]> | null>
  businessId: string
}) => {
  const { data, refetch } = useApplicationTemplate(businessId)

  const { data: fields, refetch: refetchCustomFields } =
    useTemplateCustomFields(businessId)

  const [showDetails, setShowDetails] = useState<boolean | number>(false)

  const { executeAsync: deleteCustomField } = useDeleteTemplateCustomFields()
  const { executeAsync: patchCustomField } = usePatchTemplateCustomFields()
  const { executeAsync: createCustomField } = useCreateTemplateCustomFields()

  if (!fields || !data) return <></>
  return (
    <Card
      style={{
        margin: "1rem 0 1rem 0",
      }}
    >
      <CardHeader title="Additional Questions" />

      <CardContent>
        <Typography>
          You can customize your application! Add questions here and they will
          appear on your application under the assigned section.
        </Typography>
        <Formik
          innerRef={bagRef}
          initialValues={fields?.slice() || []}
          enableReinitialize
          onSubmit={(values: Array<TemplateCustomField>) => {
            const idsAccountedFor = new Array<string>()
            const promiseArray = new Array<Promise<unknown>>()
            for (const value of values) {
              if (value.id) {
                idsAccountedFor.push(value.id)
                promiseArray.push(patchCustomField(value))
              } else {
                promiseArray.push(createCustomField(value))
              }
            }
            fields
              .filter(
                (field) => field.id && !idsAccountedFor.includes(field.id),
              )
              .forEach((field) => {
                promiseArray.push(deleteCustomField(field))
              })
            Promise.all(promiseArray)
              .then(() => {
                refetch()
                refetchCustomFields()
                enqueueSnackbar("Settings saved.", {
                  variant: "success",
                })
                setShowDetails(false)
                return 1
              })
              .catch((err) => {
                error(err)
              })
          }}
        >
          {(props) => (
            <>
              <Form>
                {props.values.map((field, index) => (
                  <Box key={index}>
                    <Grid
                      container
                      columnSpacing={3}
                      rowSpacing={1}
                      style={{
                        margin: "1rem",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <Grid item sm={12} md={9}>
                        <Typography>{field.text}</Typography>
                      </Grid>
                      <Grid item sm={12} md={1}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setShowDetails(index)
                          }}
                        >
                          Edit
                        </Button>
                      </Grid>

                      <Grid item sm={12} md={2}>
                        <IconButton
                          color="error"
                          onClick={() => {
                            confirm(
                              "You are about to remove this question from your application",
                            )
                              .then(
                                () => {
                                  return deleteCustomField(field)
                                },
                                () => {
                                  info("cancelled")
                                },
                              )
                              .then(() => {
                                refetch()
                                refetchCustomFields()
                                return enqueueSnackbar("Settings saved.", {
                                  variant: "success",
                                })
                              })
                              .catch(() => {
                                error("cancelled")
                              })
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                ))}

                {showDetails !== false && (
                  <CustomFieldConfigView
                    data={data}
                    value={
                      showDetails === true
                        ? undefined
                        : props.values[showDetails]
                    }
                    onClose={() => {
                      setShowDetails(false)
                    }}
                    onSave={(field) => {
                      const values = props.values
                      if (showDetails === true) {
                        values.push(field)
                      } else {
                        values[showDetails] = field
                      }
                      props
                        .setValues(values, false)
                        .then(() => {
                          return props.submitForm()
                        })
                        .catch(() => {
                          error("cancelled")
                        })
                    }}
                  />
                )}

                <Box
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "end",
                    margin: "1rem 0 0 0",
                  }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    startIcon={<AddOutlined />}
                    onClick={() => {
                      // props.setValues(
                      //   [
                      //     {
                      //       text: "",
                      //       fieldType: "text",
                      //       template: data.id,
                      //       required: true,
                      //     },
                      //   ].concat(props.values || []),
                      // )
                      setShowDetails(true)
                    }}
                  >
                    Add Question
                  </Button>
                </Box>
              </Form>
            </>
          )}
        </Formik>
      </CardContent>
    </Card>
  )
}
