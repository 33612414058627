// @mui

import { CreditApplication } from "src/types"
import { Edit } from "@mui/icons-material"
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Stack,
} from "@mui/material"
import { useState } from "react"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import EditBankReferenceDialog from "./components/EditBankReferenceDialog"
import BankReferenceChartDialog from "./components/BankReferenceChartDialog"
import { PAGE_LABEL_BANK_REFERENCE } from "../intake_sections/constants"
import SkippedMessage from "../components/SkippedMessage"
import { renderCustomQuestionsFor } from "../utils"
import PDFOrImageViewerDialog from "src/components/pdf/PDFOrImageViewerDialog"

// ----------------------------------------------------------------------

export default function ({
  application,
  refetchApplication,
}: {
  application: CreditApplication
  refetchApplication: () => void
}) {
  // const [showBank, setShowBank] = useState(false)

  const [add, setAdd] = useState<string | undefined>(undefined)
  const [showBankReferenceDialog, setBankReferenceDialog] = useState(false)

  const { data: template } = useApplicationTemplate()

  // const { data, refetch } = useBankInfo(application.id || "")

  // useEffect(() => {
  //   if (showBank) {
  //     refetch()
  //     setTimeout(() => {
  //       setShowBank(false)
  //     }, 10000)
  //   }
  // }, [showBank, refetch])

  const isSectionEnabled = (label: string) => {
    return (
      template?.formTemplate?.pages.find((page) => page.label === label)
        ?.enabled === true
    )
  }

  const [viewDocument, setViewDocument] = useState<string | undefined>(
    undefined,
  )

  if (isSectionEnabled(PAGE_LABEL_BANK_REFERENCE) === false) return <></>

  return (
    <Card>
      <CardHeader title="Bank Reference" />

      <Stack direction="row" sx={{ gap: 3, padding: "1.5rem 1.5rem 0 1.5rem" }}>
        {application.data?.plaidData && "Digital Reference Available"}
        {!application.data?.plaidData && (
          <Stack alignItems="flex-start" sx={{ width: "100%" }}>
            <SkippedMessage
              label={PAGE_LABEL_BANK_REFERENCE}
              application={application}
            />
            <Stack
              alignItems="flex-start"
              sx={{ typography: "body2", gap: 1, p: 3 }}
            >
              <>
                <Stack direction="row" alignItems="center">
                  <Box
                    component="span"
                    sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
                  >
                    Account Number
                  </Box>
                  {application.data.bankAccountNumber}
                </Stack>

                <Stack direction="row" alignItems="center">
                  {application.data.businessCountry === "United States" && (
                    <>
                      <Box
                        component="span"
                        sx={{
                          color: "text.secondary",
                          width: 120,
                          flexShrink: 0,
                        }}
                      >
                        Routing Number
                      </Box>

                      {application.data.bankRoutingNumber}
                    </>
                  )}
                  {application.data.businessCountry === "Canada" && (
                    <>
                      <Box
                        component="span"
                        sx={{
                          color: "text.secondary",
                          width: 120,
                          flexShrink: 0,
                        }}
                      >
                        Institution/Transit Number
                      </Box>
                      {`${application.data.bankInstitutionNumber}-${application.data.bankTransitNumber}`}
                    </>
                  )}
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Box
                    component="span"
                    sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
                  >
                    Bank Name
                  </Box>
                  {application.data.bankName}
                </Stack>
              </>
              <Stack direction="row" alignItems="center">
                <Box
                  component="span"
                  sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
                >
                  Contact Name
                </Box>
                {application.data.bankContactName}
              </Stack>
              <Stack direction="row" alignItems="center">
                <Box
                  component="span"
                  sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
                >
                  Contact Email
                </Box>
                {application.data.bankContactEmail}
              </Stack>

              <Stack direction="row" alignItems="center">
                <Box
                  component="span"
                  sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
                >
                  Contact Phone Number
                </Box>
                {application.data.bankContactPhoneNumber}
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>

      <Box style={{ padding: "0 1.5rem 0 1.5rem" }}>
        {renderCustomQuestionsFor(
          template,
          application,
          PAGE_LABEL_BANK_REFERENCE,
          () => null,
          (file) => {
            setViewDocument(file)
          },
        )}
      </Box>
      <PDFOrImageViewerDialog
        fileId={viewDocument}
        onClose={() => {
          setViewDocument(undefined)
        }}
      />

      <CardActions style={{ justifyContent: "end" }} sx={{ p: 3 }}>
        <Button
          variant="contained"
          startIcon={<Edit />}
          onClick={() => {
            setAdd(application.id)
          }}
        >
          Add/Edit Reference
        </Button>
        {application.data?.plaidData && (
          <>
            <BankReferenceChartDialog
              application={application}
              onClose={() => setBankReferenceDialog(false)}
              open={showBankReferenceDialog}
            />
            <Button
              variant="contained"
              // startIcon={<Edit />}
              onClick={() => {
                // TODO: Remove ability to view power BI report after charts are generated
                if (application.data?.powerbiLink) {
                  window.open(application.data?.powerbiLink)
                } else {
                  setBankReferenceDialog(true)
                }
              }}
            >
              View Digital Report
            </Button>
          </>
        )}
        {/* {!showBank && application.data.stripeAcctId && (
          <Button
            variant="contained"
            startIcon={<Visibility />}
            onMouseDown={() => setShowBank(true)}
            onMouseUp={() => setShowBank(false)}
          >
            View Account Number
          </Button>
        )} */}
      </CardActions>
      {add && (
        <EditBankReferenceDialog
          id={add}
          open={!!add}
          onClose={() => {
            setAdd(undefined)
            refetchApplication()
            // refetch()
          }}
        />
      )}
    </Card>
  )
}
