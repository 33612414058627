import { Button, Paper, Typography } from "@mui/material"
import { useInternalBusiness } from "src/queries/internal/useInternalBusiness"
import { confirm } from "src/components/confirm"
import { useCreateInternalUrlShorterner } from "src/queries/internal/useCreateInternalUrlShorterner"
import { enqueueSnackbar } from "notistack"

export default function CreditUrlPopulation({ id }: { id: string }) {
  const { data: businessData, refetch: businessRefetch } = useInternalBusiness(
    id as string,
  )
  const shortcut = () => {
    if (businessData) {
      return businessData.shortenedUrl
        ? businessData.shortenedUrl
        : (businessData.name.replaceAll(" ", "").toLowerCase() as string)
    }
  }

  const { execute } = useCreateInternalUrlShorterner(() => {
    enqueueSnackbar("Url Shorterner updated", { variant: "success" })
    businessRefetch()
  })

  return (
    <>
      {" "}
      {businessData && (
        <Paper elevation={3} sx={{ marginY: 2, p: 2 }}>
          <Typography variant="h6" sx={{ marginBottom: 1 }}>
            Credit Application URL
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              confirm(
                `Are you sure you want to add ${shortcut()} as the shortened url for credit applications`,
              )
                .then(() => {
                  execute(
                    {
                      url: `https://my.netnow.io/ua-trade-credit/base?business_id=${businessData.id}`,
                      shortcut: shortcut() as string,
                    },
                    () => {
                      businessRefetch()
                    },
                  )
                  return
                })
                .catch(() => {
                  return
                })
            }}
          >
            Update shortened url of {businessData.name} to {shortcut()}
          </Button>
        </Paper>
      )}
    </>
  )
}
