import {
  ArrowForward,
  ExpandMoreOutlined,
  Search,
  Lock,
  ThumbUp,
} from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  useTheme,
  Stack,
  Grid,
} from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"
import { useSnackbar } from "notistack"
import React, { useEffect } from "react"
import { usePlaidLink } from "react-plaid-link"
import { Application, ApplicationTemplate, CreditApplication } from "src/types"
import { usePlaidLinkToken } from "src/queries"
import ReactPhoneInput from "react-phone-input-material-ui"
import { PAGE_LABEL_BANK_REFERENCE } from "./constants"
import { useAnonymousBusiness } from "src/queries/credit/useAnonymousBusiness"
import ReusableCustomQuestions from "../components/ReusableCustomQuestions"
import { isRoutingEnabled } from "./schemas/BankReferenceSectionSchema"
import { isPageRequired } from "./template_helpers"
import * as Sentry from "@sentry/react"
import { customWordMapping } from "src/statics"
const useStyles = makeStyles(() =>
  createStyles({
    MuiAccordionroot: {
      "&:before": {
        display: "none",
      },
    },
  }),
)

const PAGE_LABEL = PAGE_LABEL_BANK_REFERENCE

type Props = {
  application: Application
  data: CreditApplication["data"]
  errors: CreditApplication["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCustomChange: (key: string, value: any) => void
  onContinue: () => void
  onSkip?: () => void
  template?: ApplicationTemplate
}

export default ({
  onContinue,
  onChange,
  onSkip,
  data,
  errors,
  template,
  onCustomChange,
  application,
}: Props) => {
  const classes = useStyles()

  const theme = useTheme()

  const { data: business } = useAnonymousBusiness(data?.seller)

  const { enqueueSnackbar } = useSnackbar()

  const getBuyerAddress = () => {
    if (business?.country) {
      return business.country
    }
    if (data?.businessCountry) {
      return data.businessCountry
    }
    if (data?.shippingCountry) {
      return data?.shippingCountry
    }

    return "United States"
  }

  const { data: linkTokenResponse } = usePlaidLinkToken(true, getBuyerAddress())

  const {
    open: openPlaid,
    ready,
    error: plaidError,
  } = usePlaidLink({
    token: linkTokenResponse?.linkToken || "",
    onSuccess: (public_token) => {
      // this will be exchanged in the next step when application is submitted
      onChange("plaidPublicToken", public_token)
      onContinue()
    },
    onExit: (error, metadata) => {
      if (error) {
        Sentry.captureException(error)
        if (error.display_message) {
          enqueueSnackbar(
            "error: " +
              error.display_message +
              ". if fails again, please use the manual bank reference.",
            {
              variant: "error",
            },
          )
        } else {
          enqueueSnackbar(
            "An error occurred. if fails again, please use the manual bank reference.",
            {
              variant: "error",
            },
          )
        }
      }
    },
    onEvent: (eventName, metadata) => {
      if (eventName === "ERROR") {
        Sentry.captureException(metadata)
      }
    },
  })
  const getDefaultExpanded = () => {
    if (data?.plaidData) {
      return "panel1"
    }

    if (data?.bankName) {
      return "panel2"
    }
    return "panel1"
  }

  const [expanded, setExpanded] = React.useState<string | false>(
    getDefaultExpanded(),
  )

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }
  useEffect(() => {
    if (plaidError) {
      Sentry.captureException(plaidError)
      if (plaidError.message) {
        enqueueSnackbar(
          "An error " +
            plaidError.message +
            "if fails again, please use the manual bank reference.",
          {
            variant: "error",
          },
        )
      } else {
        enqueueSnackbar(
          "An error occurred. if fails again, please use the manual bank reference.",
          {
            variant: "error",
          },
        )
      }
    }
  }, [plaidError, enqueueSnackbar])

  return (
    <>
      <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
        Bank Reference
        {isPageRequired(PAGE_LABEL, template, data)
          ? ""
          : ` (${customWordMapping("Optional", template)})`}
      </Typography>
      <Typography
        gutterBottom
        variant="body2"
        sx={{ color: "text.primary", textAlign: "left" }}
      >
        A bank reference can be provided in one of two ways. The{" "}
        <strong>Instant</strong> bank reference provides a secure, digital bank
        reference to us and helps us make a credit decision much faster.{" "}
        <strong>Manual</strong> bank reference can also be provided and will
        require you to provide your account number and contact information of
        your branch / account manager.
      </Typography>
      <Divider style={{ margin: "16px 0 16px 0" }} />
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        style={{
          border: "solid",
          borderWidth: "1px",
          borderColor: theme.palette.success.dark,
          borderRadius: "16px",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant="h6">Instant Bank Reference</Typography>
          <Typography
            variant="caption"
            sx={{
              padding: "5px",
              borderRadius: "4px",
              color: theme.palette.success.dark,
              marginLeft: "4px",
              backgroundColor: "rgb(34, 154, 22, 0.1)",
            }}
          >
            <strong>Recommended</strong>
          </Typography>
          <Typography
            variant="caption"
            sx={{
              marginLeft: "auto",
              color: "text.secondary",
              marginTop: "0.5em",
            }}
          >
            1 minute or less to complete
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!data.plaidData && (
            <>
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  p: 1,
                  backgroundColor: "rgb(34, 154, 22, 0.1)",
                }}
              >
                <Typography variant="caption">
                  <Lock
                    sx={{
                      color: theme.palette.success.dark,
                      marginRight: "2px",
                    }}
                  />
                </Typography>
                <Typography variant="subtitle2" sx={{ marginTop: "0.26em" }}>
                  Your data is protected with 256-bit encryption
                </Typography>
              </Stack>
              <Grid sx={{ p: 1 }}>
                <Grid item>
                  <Typography variant="h6">How It works</Typography>
                </Grid>
                <Grid item>
                  <Stack direction="row" sx={{ p: 1 }}>
                    <Stack sx={{ paddingTop: "0.4em" }}>
                      <Typography variant="body1">
                        <strong>Step 1</strong>
                      </Typography>
                    </Stack>
                    <Stack sx={{ paddingX: 1, margin: 1 }}>
                      <img
                        src="/assets/images/plaid-logo.png"
                        style={{ width: "33px", borderRadius: "4px" }}
                      />
                    </Stack>
                    <Stack>
                      <Typography variant="h6">
                        You select your financial institution through Plaid
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: "text.secondary" }}
                      >
                        Plaid has partnered with 11,000+ financial institutions
                        across the US and Canada
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" sx={{ p: 1 }}>
                    <Stack sx={{ paddingTop: "0.4em" }}>
                      <Typography variant="body1">
                        <strong>Step 2</strong>
                      </Typography>
                    </Stack>
                    <Stack sx={{ paddingX: 1, margin: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{
                          backgroundColor: "rgb(34, 154, 22, 0.1)",
                          borderRadius: "4px",
                          paddingTop: 0.5,
                          paddingLeft: 0.6,
                          paddingRight: 0.6,
                        }}
                      >
                        <Lock
                          sx={{
                            color: theme.palette.success.dark,
                          }}
                        />
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography variant="h6">
                        You login securely into your bank
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: "text.secondary" }}
                      >
                        NetNow or the supplier will never get access to
                        credentials
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" sx={{ p: 1 }}>
                    <Stack sx={{ paddingTop: "0.4em" }}>
                      <Typography variant="body1">
                        <strong>Step 3</strong>
                      </Typography>
                    </Stack>
                    <Stack sx={{ paddingX: 1, margin: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{
                          backgroundColor: "#B95CF4",
                          borderRadius: "4px",
                          paddingTop: 0.5,
                          paddingLeft: 0.8,
                          paddingRight: 0.8,
                        }}
                      >
                        <ThumbUp
                          fontSize="small"
                          sx={{ color: theme.palette.info.contrastText }}
                        />
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography variant="h6">
                        You Give Consent for Bank Reference Authorization
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: "text.secondary" }}
                      >
                        You share limited READ-ONLY information like account
                        balance and transactions
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ paddingX: 1 }}
                  >
                    <Stack sx={{ marginTop: 3 }}>
                      <Stack>
                        <Typography variant="caption">
                          <strong>
                            Plaid is trusted by 1000+ organizations Including:
                          </strong>
                        </Typography>
                      </Stack>
                      <Stack direction="row" sx={{ marginTop: 1 }}>
                        <Stack sx={{ paddingX: 1.1 }}>
                          <img
                            src="/assets/images/Venmo.png"
                            style={{
                              width: "80px",
                              opacity: 0.2,
                            }}
                          />
                        </Stack>
                        <Stack sx={{ paddingX: 1 }}>
                          <img
                            src="/assets/images/Betterment.png"
                            style={{ width: "140px", opacity: 0.2 }}
                          />
                        </Stack>
                        <Stack sx={{ paddingX: 1 }}>
                          <img
                            src="/assets/images/Dave.png"
                            style={{ width: "70px", opacity: 0.2 }}
                          />
                        </Stack>
                        <Stack sx={{ paddingX: 1, marginTop: -0.5 }}>
                          <img
                            src="/assets/images/RBC.png"
                            style={{ width: "20px", opacity: 0.2 }}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack>
                      <LoadingButton
                        loading={!ready}
                        endIcon={<ArrowForward />}
                        size="large"
                        onClick={() => {
                          openPlaid()
                        }}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Find my bank
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    align="center"
                    sx={{ color: "text.secondary" }}
                  >
                    <b>Warning</b>: Please disconnect from VPNs to use{" "}
                    <b>Instant Bank Reference</b>
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          {data.plaidData &&
            data?.plaidData?.bankInfo?.accounts?.map((a: any) => (
              <Stack key={a.account_id}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    alignItems: "center",
                    justifyContent: "start",
                    width: "100%",
                  }}
                >
                  <Typography>{a.name}</Typography>
                  <Typography>{a.officialName}</Typography>
                </Box>
                <Typography>**********{a.mask}</Typography>
                <LoadingButton
                  onClick={() => {
                    onContinue()
                  }}
                  size="large"
                  fullWidth
                  endIcon={<ArrowForward />}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Continue
                </LoadingButton>
                <LoadingButton
                  loading={!ready}
                  startIcon={<Search />}
                  size="large"
                  onClick={() => {
                    onChange("plaidData", undefined)
                    openPlaid()
                  }}
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Connect another account
                </LoadingButton>
              </Stack>
            ))}
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        style={{
          border: "solid",
          borderWidth: "1px",
          borderColor: "#D6E4FF",
          borderRadius: "16px",
          marginTop: "16px",
        }}
        classes={{
          root: classes.MuiAccordionroot,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
          <Typography variant="h6" sx={{ width: "33%", flexShrink: 0 }}>
            Manual
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Provide details of your bank account and representative. This
            typically results in a longer approval process and might result in a
            lower credit limit
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
            We will not use this information for any payment processing.
          </Typography>

          <TextField
            margin="normal"
            fullWidth
            label="Bank Name"
            value={data.bankName}
            onChange={(event) => {
              onChange("bankName", event.target.value)
            }}
            required={isPageRequired(PAGE_LABEL, template, data)}
            error={Boolean(errors?.bankName)}
          />
          {data.businessCountry === "Canada" && (
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "32px",
              }}
            >
              <TextField
                margin="normal"
                fullWidth
                label="Account Number"
                value={data.bankAccountNumber}
                onChange={(event) => {
                  onChange("bankAccountNumber", event.target.value.trim())
                }}
                error={Boolean(errors?.bankAccountNumber)}
                required={isPageRequired(PAGE_LABEL, template, data)}
                helperText={errors?.bankAccountNumber}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Transit Number"
                value={data.bankTransitNumber}
                onChange={(event) => {
                  onChange("bankTransitNumber", event.target.value.trim())
                }}
                required={isPageRequired(PAGE_LABEL, template, data)}
                error={Boolean(errors?.bankTransitNumber)}
                helperText={errors?.bankTransitNumber}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Institution Number"
                value={data.bankInstitutionNumber}
                onChange={(event) => {
                  onChange("bankInstitutionNumber", event.target.value.trim())
                }}
                required={isPageRequired(PAGE_LABEL, template, data)}
                error={Boolean(errors?.bankInstitutionNumber)}
                helperText={errors?.bankInstitutionNumber}
              />
            </Box>
          )}
          {data.businessCountry === "United States" && (
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "32px",
              }}
            >
              <TextField
                margin="normal"
                fullWidth
                label="Account Number"
                value={data.bankAccountNumber}
                onChange={(event) => {
                  onChange("bankAccountNumber", event.target.value.trim())
                }}
                required={isPageRequired(PAGE_LABEL, template, data)}
                error={Boolean(errors?.bankAccountNumber)}
                helperText={errors?.bankAccountNumber}
              />
              {isRoutingEnabled(template, data) && (
                <TextField
                  margin="normal"
                  fullWidth
                  label="Routing Number"
                  value={data.bankRoutingNumber}
                  onChange={(event) => {
                    onChange("bankRoutingNumber", event.target.value.trim())
                  }}
                  required={isPageRequired(PAGE_LABEL, template, data)}
                  error={Boolean(errors?.bankRoutingNumber)}
                  helperText={errors?.bankRoutingNumber}
                />
              )}
            </Box>
          )}
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "32px",
            }}
          >
            <TextField
              margin="normal"
              fullWidth
              label="Account Manager Name"
              value={data.bankContactName}
              onChange={(event) => {
                onChange("bankContactName", event.target.value)
              }}
              required={isPageRequired(PAGE_LABEL, template, data)}
              error={Boolean(errors?.bankContactName)}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Account Manager Email"
              value={data.bankContactEmail}
              onChange={(event) => {
                onChange("bankContactEmail", event.target.value)
              }}
              required={isPageRequired(PAGE_LABEL, template, data)}
              error={Boolean(errors?.bankContactEmail)}
            />
          </Box>
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "32px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "5%",
              }}
            >
              <ReactPhoneInput
                value={data.bankContactPhoneNumber}
                country={"us"}
                onChange={(event) => {
                  onChange("bankContactPhoneNumber", "+" + event)
                }}
                countryCodeEditable={false}
                label="Account Manager Phone Number"
                component={TextField}
                containerStyle={{ width: "100%", marginTop: "8px" }}
                inputProps={{
                  required: isPageRequired(PAGE_LABEL, template, data),
                  error: Boolean(errors?.bankContactPhoneNumber),
                }}
              />
            </Box>
          </Box>

          {template?.customFields && (
            <ReusableCustomQuestions
              application={application}
              associatedPage={PAGE_LABEL}
              dataKeyResponses={data}
              data={data["customFields"]}
              errors={errors}
              onChange={onCustomChange}
            />
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "2rem",
            }}
          >
            <Button
              onClick={() => {
                onContinue()
              }}
              size="large"
              fullWidth
              endIcon={<ArrowForward />}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit Manual Reference
            </Button>
            {template?.formTemplate.pages.find(
              (step) => step.label === PAGE_LABEL,
            )?.skippable &&
              onSkip && (
                <Button
                  id="continue-owner"
                  onClick={() => {
                    if (onSkip) onSkip()
                  }}
                  size="large"
                  variant="outlined"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Skip
                </Button>
              )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
