import { ApplicationTemplate } from "src/types"
import CashSectionUserDetails from "./ApplicationTemplate/CashSectionUserDetails"
import CashSectionBusinessDetails from "./ApplicationTemplate/CashSectionBusinessDetails"
import CashSectionZohoTemplateId from "./ApplicationTemplate/CashSectionZohoTemplateId"

export default function EditCreditApplicationTemplate({
  applicationTemplate,
  refetch,
}: {
  applicationTemplate: ApplicationTemplate
  refetch: () => void
}) {
  return (
    <>
      <CashSectionZohoTemplateId
        applicationTemplate={applicationTemplate}
        refetch={refetch}
      />
      <CashSectionUserDetails
        applicationTemplate={applicationTemplate}
        refetch={refetch}
      />
      <CashSectionBusinessDetails
        applicationTemplate={applicationTemplate}
        refetch={refetch}
      />
    </>
  )
}
