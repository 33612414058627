import { Button, Grid, Stack, TextField, Typography } from "@mui/material"
import { Formik } from "formik"
import { enqueueSnackbar } from "notistack"
import { usePatchCreditApplicationTemplate } from "src/queries/internal/usePatchCreditApplicationTemplate"
import { ApplicationTemplate, ApplicationTemplateNotes } from "src/types"
import { convertArrayToString } from "src/utils/utils"

export default function EditCreditEmailNotes({
  applicationTemplate,
  refetch,
}: {
  applicationTemplate: ApplicationTemplate
  refetch: () => void
}) {
  const { execute, isLoading } = usePatchCreditApplicationTemplate(() => {
    enqueueSnackbar("Credit Email Notes Updated", { variant: "success" })
  })
  return (
    <>
      <Grid container sx={{ marginY: 2 }}>
        <Formik
          initialValues={{
            id: applicationTemplate.id,
            approvalNotes: convertArrayToString(
              applicationTemplate.approvalNotes,
            ),
            invitationNotes: convertArrayToString(
              applicationTemplate.invitationNotes,
            ),
            rejectionNotes: convertArrayToString(
              applicationTemplate.rejectionNotes,
            ),
            tradeReferenceNotes: applicationTemplate.tradeReferenceNotes,
            approvalTerms: convertArrayToString(
              applicationTemplate.approvalTerms,
            ),
            customWordMapping: convertArrayToString(
              applicationTemplate.customWordMapping,
            ),
          }}
          onSubmit={(values) => {
            try {
              if (
                values.approvalNotes &&
                typeof values.approvalNotes === "string"
              ) {
                values.approvalNotes = JSON.parse(values.approvalNotes)
              }
              if (
                values.invitationNotes &&
                typeof values.invitationNotes === "string"
              ) {
                values.invitationNotes = JSON.parse(values.invitationNotes)
              }
              if (
                values.rejectionNotes &&
                typeof values.rejectionNotes === "string"
              ) {
                values.rejectionNotes = JSON.parse(values.rejectionNotes)
              }
              if (
                values.approvalTerms &&
                typeof values.approvalTerms === "string"
              ) {
                values.approvalNotes = JSON.parse(values.approvalTerms)
              }
              if (
                values.customWordMapping &&
                typeof values.customWordMapping === "string"
              ) {
                values.customWordMapping = JSON.parse(values.customWordMapping)
              }
              execute(values as ApplicationTemplateNotes, () => {
                refetch()
              })
            } catch (error) {
              alert(
                "Invalid JSON plus cross check approval, invitation and rejection notes",
              )
            }
          }}
        >
          {(props) => (
            <>
              <Typography variant="h6">
                Notes / Approval Terms and Mappings
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={8}
                label="Approval Notes"
                id="approvalNotes"
                name="approvalNotes"
                margin="normal"
                type="text"
                value={convertArrayToString(props.values.approvalNotes)}
                onChange={props.handleChange}
                error={Boolean(props.errors?.approvalNotes)}
              />
              <TextField
                fullWidth
                multiline
                rows={8}
                label="Invitation Notes"
                id="invitationNotes"
                name="invitationNotes"
                margin="normal"
                type="text"
                value={convertArrayToString(props.values.invitationNotes)}
                onChange={props.handleChange}
                error={Boolean(props.errors?.invitationNotes)}
              />
              <TextField
                fullWidth
                multiline
                rows={8}
                label="Rejection Notes"
                id="rejectionNotes"
                name="rejectionNotes"
                margin="normal"
                type="text"
                value={convertArrayToString(props.values.rejectionNotes)}
                onChange={props.handleChange}
                error={Boolean(props.errors?.rejectionNotes)}
              />
              <TextField
                fullWidth
                multiline
                rows={8}
                label="Trade Reference Notes"
                id="tradeReferenceNotes"
                name="tradeReferenceNotes"
                margin="normal"
                type="text"
                value={props.values.tradeReferenceNotes}
                onChange={props.handleChange}
                error={Boolean(props.errors?.tradeReferenceNotes)}
              />
              <TextField
                fullWidth
                multiline
                rows={8}
                label="Approval Terms"
                id="approvalTerms"
                name="approvalTerms"
                margin="normal"
                type="text"
                value={convertArrayToString(props.values.approvalTerms)}
                onChange={props.handleChange}
                error={Boolean(props.errors?.approvalTerms)}
              />
              <TextField
                fullWidth
                multiline
                rows={8}
                label="Custom Word Mapping"
                id="customWordMapping"
                name="customWordMapping"
                margin="normal"
                type="text"
                value={convertArrayToString(props.values.customWordMapping)}
                onChange={props.handleChange}
                error={Boolean(props.errors?.customWordMapping)}
              />
              <Stack>
                <Button
                  variant="contained"
                  onClick={() => {
                    props.handleSubmit()
                  }}
                  disabled={isLoading}
                >
                  Update
                </Button>
              </Stack>
            </>
          )}
        </Formik>
      </Grid>
    </>
  )
}
